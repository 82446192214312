import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Drawer, List, ListItem, ListItemButton, ListItemIcon, ListItemText, TextField } from '@mui/material';

import QuizIcon from '@mui/icons-material/Quiz';
import CloseIcon from '@mui/icons-material/Close';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import QrCodeIcon from '@mui/icons-material/QrCode';

import styles from './Menu.module.scss';
// import classNames from 'classnames';

import logo from '../../assets/sf_red_logo.png';

import { setCycleTime } from '../../store/reducers/displaySlice';

const DisplayMenu = (props) => {
  const { menuOpen, setMenuOpen } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const cycleTiming = useSelector((state) => state.display.cycleTime);

  const goHome = (e) => {
    setMenuOpen(false);
    setTimeout(() => {
      navigate('/playtrivia');
    }, 700);
  };
  // const goPhotoReg = (e) => {
  //     setMenuOpen(false);
  //     setTimeout(() => { navigate('/photo') }, 700)
  // }
  const goTo = (path) => {
    setMenuOpen(false);
    setTimeout(() => {
      navigate(path);
    }, 700);
  };

  return (
    <Drawer className={styles.menuDrawer} anchor={'left'} open={menuOpen} onClose={() => setMenuOpen(false)}>
      <div className={styles.menuHeader}>
        <img src={logo} alt='' className={styles.menuLogo} />
        <div className={styles.menuCloseButton} onClick={() => setMenuOpen(false)}>
          <CloseIcon />
        </div>
      </div>
      <List>
        {/* <ListItem className={styles.menuItemInsets} key={'trivia_link'} onClick={goHome} disablePadding >
                <ListItemButton>
                    <ListItemIcon>
                        <QuizIcon fontSize="small" />

                    </ListItemIcon>
                    <ListItemText primary={'Go to Trivia'} />
                </ListItemButton>
            </ListItem> */}
        <ListItem className={styles.menuItemInsets} key={'jingle_link'} onClick={(e) => goTo('/jjq')} disablePadding>
          <ListItemButton>
            <ListItemIcon>
              <QuizIcon fontSize='small' />
            </ListItemIcon>
            <ListItemText primary={'Go to Jingle Quest'} />
          </ListItemButton>
        </ListItem>
        <ListItem
          className={styles.menuItemInsets}
          key={'registration_link'}
          onClick={(e) => goTo('/photo')}
          disablePadding
        >
          <ListItemButton>
            <ListItemIcon>
              <CameraAltIcon fontSize='small' />
            </ListItemIcon>
            <ListItemText primary={'Go to Photo Registration'} />
          </ListItemButton>
        </ListItem>
        <ListItem className={styles.menuItemInsets} key={'baqr_link'} onClick={(e) => goTo('/baqr')} disablePadding>
          <ListItemButton>
            <ListItemIcon>
              <QrCodeIcon fontSize='small' />
            </ListItemIcon>
            <ListItemText primary={'Go to BA QR'} />
          </ListItemButton>
        </ListItem>
        <ListItem className={styles.menuItemInsets} key={'statefarmlinkC'} onClick={() => {}}>
          {/* <ListItemButton> */}
          <ListItemIcon>
            {/* <RadioButtonUncheckedIcon fontSize="small" /> */}
            <TextField
              type='number'
              inputProps={{
                min: 5,
                max: 1000,
                inputMode: 'numeric',
                pattern: '[0-9]*'
              }}
              className={styles.timingInput}
              value={cycleTiming}
              onChange={(e) => {
                if (e.currentTarget.value >= 5) {
                  dispatch(setCycleTime(e.currentTarget.value));
                }
              }}
              helperText={'Enter slide timing in seconds (5-1000)'}
            />
          </ListItemIcon>
          {/* </ListItemButton> */}

          {/* <ListItemText className={styles.timingInput} primary={'Timing'} /> */}
        </ListItem>
      </List>
      <div className={styles.menuFooter}>
        <div className={styles.menuFooterText}>© 2023 - All Rights Reserved</div>
      </div>
    </Drawer>
  );
};

export default DisplayMenu;
