import React, { useEffect } from 'react';
import styles from './TacDisplay.module.scss';
import classNames from 'classnames';
import { Button } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Terms from './Terms';
import Privacy from './Privacy';
import DNS from './DNS';

const USE_SF_LINKS = false;

function TacDisplay(props) {
  const { openLink, setOpenLink } = props;
  const [link, setLink] = React.useState(null);

  useEffect(() => {
    // console.log('openLink: ', openLink);
    if (openLink === 'terms') {
      setLink('https://www.statefarm.com/customer-care/disclosures/terms-of-use');
      // setLink('https://www.infinitymarketing.com/terms-and-conditions/');
    } else if (openLink === 'dns') {
      setLink('https://www.statefarm.com/customer-care/privacy-security/privacy/california-privacy-rights/do-not-sell');
    } else if (openLink === 'privacy') {
      setLink('https://www.statefarm.com/customer-care/privacy-security');
      // setLink('https://www.infinitymarketing.com/privacy/');
    } else if (openLink === 'rules') {
      setLink('https://mecum.statefarmactivations.com/rules.pdf');
    } else {
      setLink(null);
    }
  }, [openLink]);

  return (
    <div
      className={classNames(styles.tacContainer, { [styles.hideTac]: openLink === null })}
      onClick={(e) => setOpenLink(null)}
    >
      <Button
        onClick={(e) => setOpenLink(null)}
        className={styles.closeButton}
        variant='contained'
        color='inherit'
        endIcon={<CloseIcon />}
      >
        Close
      </Button>

      {(USE_SF_LINKS || openLink === 'rules') && (
        <iframe src={link} title='Terms and Conditions' className={styles.tac} />
      )}
      {!USE_SF_LINKS && openLink === 'terms' && <Terms />}
      {!USE_SF_LINKS && openLink === 'privacy' && <Privacy />}
      {!USE_SF_LINKS && openLink === 'dns' && <DNS />}
    </div>
  );
}

export default TacDisplay;
