import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import moment from 'moment';
// import APIRequestWrapper from '../..//util/api/APIRequestWrapper';
// import { setTokens, setUser } from '../../reducers/userSlice';
// import { setLocalTokens, useAuth } from '../../components/Auth/IMTAuthProvider';
// import { useAlert } from '../../hooks/useAlert';
import { Button, Col, Container, FloatingLabel, Form, FormGroup, Image, Row, Fade } from 'react-bootstrap';
import Terms from '../../Components/TacDisplay/Terms';
import Privacy from '../../Components/TacDisplay/Privacy';
import Rules from '../../Components/TacDisplay/Rules';
import BGForm from '../../Components/Form/BGForm';
import reg from '../../api/register';
import { setToken } from '../../store/reducers/userSlice';
import DialogPrompt from '../../Components/RADialog/DialogPrompt';

import styles from './JJQ.module.scss';
import DNS from '../../Components/TacDisplay/DNS';
import { Note1, Note2, Tire } from '../../Components/Notes';
import DisplayMenu from '../../Components/Menu/DisplayMenu';
import Menu from '../../Components/Menu/Menu';

const emailRex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const verifyEmail = (value) => {
  if (emailRex.test(value)) {
    return true;
  }
  return false;
};
const dtFrmt = 'HH:mm:ss';
const codeRex = /^\d{6}$/;
// const verifyCode = (code) => {
//   if (codeRex.test(code)) {
//     return true;
//   }
//   return false;
// };

function Login() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [menuOpen, setMenuOpen] = useState(false);
  const [timeData, setTimeData] = useState({ startTime: moment().format(dtFrmt) });
  const [fade, setFade] = useState(true);
  const [searchParams] = useSearchParams();
  const [verified, setVerified] = useState(false);
  const [autoLogin] = React.useState(searchParams.get('autoLogin') || false);
  const startEmail = searchParams.get('email') || '';
  const [login, setLogin] = React.useState({
    email: startEmail,
    message: '',
    loading: false
  });

  const [form, setForm] = React.useState({ show: false, type: '', data: null });

  const checkUser = useCallback(
    (email) => {
      console.log('checking user', email, login);
      setLogin((prev) => ({ ...prev, loading: true }));
      reg
        .checkUser({ email, regType: 'jjq' })
        .then((res) => {
          console.log('checkUserReg', res);
          if (res.tokens?.AccessToken) {
            // User Found
            dispatch(setToken(res.tokens));
            // setFade(false);
            // setTimeout(() => {
            setLogin((prev) => ({ ...prev, loading: false, message: '' }));
            navigate('/jjq/play');
            // }, 700);
          } else {
            // User Not Found (Redundant, but for clarity)
            console.log('checkUserReg', res);
            // setFade(false);
            // setTimeout(() => {
            setVerified(true); // shows registration form
            setLogin((prev) => ({ ...prev, loading: false }));
            //   setFade(true);
            // }, 700);
            setTimeData((prev) => ({ ...prev, emailCheckAt: moment().format(dtFrmt) }));
          }
        })
        .catch((err) => {
          console.log('[ERROR CHECKING USER] ', err, login);
          // setFade(false);
          // setTimeout(() => {
          setVerified(true); //to show registration form
          setLogin((prev) => ({ ...prev, loading: false }));
          //   setFade(true);
          // }, 700);
          setTimeData((prev) => ({ ...prev, emailCheckAt: moment().format(dtFrmt) }));
        });
    },
    [reg, setLogin, dispatch, navigate, setVerified, login]
  );

  React.useEffect(() => {
    if (autoLogin && startEmail && verifyEmail(startEmail)) {
      checkUser(startEmail);
    }
  }, [autoLogin, startEmail]);

  const next = (e, email) => {
    e?.preventDefault();
    console.log('next', email);
    checkUser(email);
  };

  const registerUser = useCallback(
    (details) => {
      setLogin((prev) => ({ ...prev, loading: true }));
      console.log('registing user', details, login);

      const dt = {
        email: login.email,
        regType: 'jjq',
        ...details,
        data: {
          regType: 'jjq',
          ageConf: details.ageConfirm,
          tandc: details.tandc,
          rulesAgree: details.rulesAgree,
          ...timeData,
          registeredAt: moment().format(dtFrmt)
        }
      };
      console.log('dt', dt);

      reg
        .registerUser(dt)
        .then((res) => {
          console.log('UserReg', res);
          if (res.tokens?.AccessToken) {
            // User Found
            dispatch(setToken(res.tokens));
            // setFade(false);
            // setTimeout(() => {
            setLogin((prev) => ({ ...prev, loading: false, message: '' }));
            navigate('/jjq/play');
            // }, 700);
          } else {
            // Error registering user
            console.log('checkUserReg', res);
            setVerified(false);
            setLogin((prev) => ({ ...prev, loading: false, message: res.message }));
          }
        })
        .catch((err) => {
          console.log('[ERROR CHECKING USER] ', err);
          setLogin((prev) => ({ ...prev, loading: false, message: err.message }));
        });
    },
    [dispatch, navigate, setLogin, setVerified, reg, login]
  );

  return (
    <div className='background-image '>
      <Fade in={fade} timeout={700}>
        <div className='container' style={{ position: 'relative' }}>
          {/* <div className=''> */}
          {!verified ? (
            <LoginForm
              //   stage={idToken == null ? 'email' : 'code'}
              email={login.email}
              setEmail={(em) => setLogin((prev) => ({ ...prev, email: em }))}
              next={next}
              loading={login.loading}
            />
          ) : (
            <RegisterForm
              submit={registerUser}
              loading={login.loading}
              form={form}
              setForm={setForm}
              backToEmail={() => setVerified(false)}
            />
          )}
          {/* {AlertComponent} */}
          {/* <DisplayForm {...localForm} hide={setLocalForm({ show: false, type: null, data: null })} /> */}
          <div className='footerContainer' onClick={(e) => setMenuOpen(true)}>
            <Image className='footerImage' src={require('../../assets/jjq/SF-Logo.png')} fluid />
          </div>
          {/* </div> */}
          <DisplayForm
            show={form.show}
            type={form.type}
            hide={() => setForm({ show: false, type: null, data: null })}
          />
          <DialogPrompt
            message={login.message}
            close={() => {
              //   dispatch(setUserError(null));
              setLogin((prev) => ({ ...prev, loading: false, message: '' }));
            }}
          />
          <Menu menuOpen={menuOpen} setMenuOpen={setMenuOpen} hideModes={true} resetPath='/jjq' />
        </div>
      </Fade>
      {/* <Note1 right='28%' top='45%' /> */}
      {/* <Note2 right='29%' top='45%' />
      <Tire left='24%' top='50%' /> */}
    </div>
  );
  // );
}

const LoginForm = ({ email, setEmail, next, loading }) => {
  // const [email, setEmail] = useState(startEmail || '');
  //   const [code, setCode] = useState('');

  const [isValid, setIsValid] = React.useState({
    email: verifyEmail(email || ''),
    code: false
  });

  React.useEffect(() => {
    setIsValid((prev) => ({ ...prev, email: verifyEmail(email) }));
  }, [email]);
  //   React.useEffect(() => {
  //     setIsValid((prev) => ({ ...prev, code: verifyCode(code) }));
  //   }, [code]);

  return (
    <div className='content'>
      <div className='loginContainer'>
        <Row className='d-flex justify-content-center loginRow'>
          <Col md='12' className='text-center' style={{ width: '100%' }}>
            {/* ################# LOGO HEADER ################# */}
            <Image
              className='mb-3 loginHeaderLogo'
              style={{ marginBottom: '3px' }}
              src={require('../../assets/jjq/login/JJQ-Logo.png')}
            />
          </Col>
          <Col xs='10' sm='8' md='8' className='mt-2' style={{ width: '80%' }}>
            <Form.Group className='text-center' style={{ textAlign: 'center' }}>
              {/* ################# EMAIL ################# */}
              <Form.Control
                type='text'
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder='Email'
                style={{ textAlign: 'center', fontSize: '18px', width: '100%' }}
                onKeyDown={(e) => e.key === 'Enter' && isValid && next(e, email, null)}
              />
            </Form.Group>
          </Col>
          {/* ################# BUTTON ################# */}
          <Col md='12' className='d-flex justify-content-center mt-2'>
            <Button
              className='loginSubmitButton mt-1 mb-3'
              disabled={loading || !isValid.email}
              variant='transparent'
              onClick={(e) => next(e, email, null)}
              style={{ backgroundColor: 'transparent', border: 'none' }}
            >
              <Image
                className='loginSubmitImage'
                src={require('../../assets/jjq/login/Play.png')}
                // style={{ width: '100%' }}
              />
            </Button>
          </Col>
        </Row>
        <BGForm />
        <Note2 right='-3%' top='45%' />
        <Tire left='-8%' top='40%' />
      </div>
    </div>
  );
};

const RegisterForm = ({ submit, loading, form, setForm, backToEmail }) => {
  const [data, setData] = useState({
    firstName: '',
    lastName: '',
    userName: '',
    rulesAgree: false,
    ageConfirm: false,
    tandc: false
  });
  const [isValid, setIsValid] = React.useState(false);
  const [stage, setStage] = React.useState('register');
  // const [form, setForm] = React.useState({ show: false, type: '', data: null });

  React.useEffect(() => {
    setIsValid(
      (stage == 'register' && data.firstName.length > 0 && data.lastName.length > 0 && data.userName.length > 0) ||
        (stage == 'tandc' && data.ageConfirm && data.rulesAgree)
    );
  }, [data, stage, setIsValid]);

  const showTerms = (type) => {
    setForm({ show: true, type, data: { message: '' } });
  };

  const back = () => {
    if (stage == 'tandc') setStage('register');
    else backToEmail();
  };

  return (
    <div className='content'>
      <div className={`${form.show ? 'd-none' : 'd-flex'} regContainer`}>
        <Row className='d-flex justify-content-center registerRow f' style={{}}>
          {/* <Col md='12' className='text-center'> */}
          {/* ################# LOGO HEADER ################# */}
          <Image
            // style={{ width: `${stage == 'register' ? '70%' : '55%'}`, maxWidth: '680px' }}
            className={`mb-1 mt-1 registerHeaderLogo stage-${stage}`}
            src={require('../../assets/jjq/login/JJQ-Logo.png')}
            fluid
          />
          {/* </Col> */}
        </Row>
        <Button
          className={`${stage == 'register' ? '' : ''} btn-sm btn-link btn-transparent btnRegBack`}
          varient='transparent'
          onClick={(e) => back()}
          style={{ backgroundColor: 'transparent', border: 'none' }}
        >
          <Image src={require('../../assets/jjq/login/Back_Gray.png')} fluid />
        </Button>
        {/*################# USER NAME AND GAMERTAG #################*/}
        <div
          className={` ${
            stage == 'tandc' ? 'd-none' : 'd-flex'
          } justify-content-center flex-direction-row col-12 flex-wrap mb-1 mt-1`}
          style={{ zIndex: 10, paddingTop: '0' }}
        >
          <div className='col-8 flex-direction-column' style={{ marginBottom: '8px' }}>
            <Form.Group className='text-center'>
              <Form.Control
                type='text'
                value={data.firstName}
                onChange={(e) => setData((prev) => ({ ...prev, firstName: e.target.value }))}
                placeholder='First Name'
                style={{ textAlign: 'center', fontSize: '18px', width: '100%' }}
              />
            </Form.Group>
          </div>
          <div className='col-8 flex-direction-column' style={{ marginBottom: '8px' }}>
            <Form.Group className='text-center'>
              <Form.Control
                type='text'
                value={data.lastName}
                onChange={(e) => setData((prev) => ({ ...prev, lastName: e.target.value }))}
                placeholder='Last Name'
                style={{ textAlign: 'center', fontSize: '18px', width: '100%' }}
              />
            </Form.Group>
          </div>
          <div className='col-8 flex-direction-column' style={{ marginBottom: '8px' }}>
            <Form.Group className='text-center'>
              <Form.Control
                type='text'
                value={data.userName}
                onChange={(e) => setData((prev) => ({ ...prev, userName: e.target.value }))}
                placeholder='Username'
                style={{ textAlign: 'center', fontSize: '18px', width: '100%' }}
              />
            </Form.Group>
          </div>
        </div>
        {/*############# T&C #################*/}
        <div
          //   className={`${stage == 'register' ? 'd-none' : 'd-flex'} justify-content-left flex-direction-col `}
          className={`${stage == 'register' ? 'd-none' : 'd-flex'} justify-content-center flex-direction-col`}
          style={{ zIndex: 10, paddingTop: '0' }}
        >
          <div className='text-center tcheader col-12 text-uppercase'>Please agree and check the following</div>
          <div className={styles.formChecks} style={{}}>
            <div className={styles.checks}>
              <input
                id='ageConf'
                type='checkbox'
                checked={data.ageConfirm}
                onChange={(e) => setData((prev) => ({ ...prev, ageConfirm: e.target.checked }))}
              />
              <div>
                I am 18 years or older{' '}
                <div style={{ fontSize: '12px', color: '#666666', lineHeight: '14px', width: '380px' }}>
                  For NE and AL residents you must be 19 years or older and for MS residents you must be 21 years or
                  older.
                </div>
                {/* I confirm that I am 18 years or older */}
              </div>
            </div>
            <div className={styles.checks}>
              <input
                id='rulesAgree'
                type='checkbox'
                checked={data.rulesAgree}
                onChange={(e) => setData((prev) => ({ ...prev, rulesAgree: e.target.checked }))}
              />
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                I have reviewed and agree to the &nbsp;
                <span onClick={(e) => showTerms('Rules')}> Jingle Quest Rules</span>.
              </div>
            </div>
            <label className={styles.checks}>
              <input
                id='tandc'
                type='checkbox'
                checked={data.tandc}
                onChange={(e) => setData((prev) => ({ ...prev, tandc: e.target.checked }))}
              />
              <div>
                I understand and consent to Sponsor using my personal information that I provide in my Contest Entry for
                business, marketing, and commercial purposes consistent with Sponsors{' '}
                <span onClick={(e) => showTerms('Privacy')}>Privacy Policy</span>.
                <div style={{ fontSize: '12px', color: '#666666', lineHeight: '14px', width: '740px' }}>
                  For CA residents:
                  <span onClick={(e) => showTerms('DNS')}> Do Not Sell or Share My Personal Information</span> (CA
                  residents only) Use for marketing may include direct and indirect communications from Sponsor and/or a
                  third party acting on Sponsor’s behalf.
                </div>
              </div>
            </label>
          </div>

          <div
            className='margin-lr-center mb-1'
            style={{ height: '3.5px', width: '400px', backgroundColor: 'red' }}
          ></div>
          <div className='col-10 d-flex justify-content-center flex-direction-col margin-lr-center'>
            <p className='text-uppercase text-center tcfs'>
              All information submitted by entrants is subject to, and will be treated in a manner consistent with,
              Sponsor’s Terms of Use and <span onClick={(e) => showTerms('Privacy')}>Privacy Policy</span>. CA residents
              please see: <span onClick={(e) => showTerms('DNS')}>Do Not Sell or Share My Personal Information</span>{' '}
              (CA residents only). By participating in Contest, entrants hereby agree that Sponsor may collect and use
              their personal information in compliance with its Privacy Policy and acknowledge that they have read and
              accepted the Sponsor Privacy Policy. By participating in Contest, entrants further agree and acknowledge
              that Sponsor may share their personal information with third parties in order for the third party to
              market Sponsor’s products and services on behalf of Sponsor, consistent with Sponsor’s Privacy Policy.
            </p>
            <p className='text-uppercase text-center tcfs'>
              Byclicking SUBMIT, you are providing express consent by electronic signature that you may have your event
              photograph sent via email by State Farm Mutual Automobile Insurance Company, its subsidiaries and
              affiliates (“State Farm”) using the email address you have provided to State Farm. By continuing, you
              agree to the terms of service.
            </p>
            <p className='text-uppercase text-center tcfs'>
              You are granting to Infinity Marketing Team, LLC (EVENT SPONSOR), their agents, licensees and assigns an
              irrevocable, non-exclusive and unrestricted license to display and use the photograph and your likeness as
              they see fit, now and in the future, and to publish, adapt, edit and/or modify the photograph in any way,
              in whole or in part, and to use such photograph in any and all media now known or hereafter discovered,
              worldwide, including but not limited to Social Media Outlets, without limitation or compensation to you
              and without right of notice, review, or approval of any such use.
            </p>
          </div>
        </div>
        <Row className='d-flex justify-content-center' style={{ zIndex: 10, paddingTop: '0' }}>
          <Col md='12' className='d-flex justify-content-center mt-1 mb-1'>
            <Button
              disabled={loading || !isValid}
              variant='transparent'
              className='registerSubmitButton mb-4'
              onClick={(e) => (stage == 'register' ? setStage('tandc') : submit(data))}
              // style={{ width: `50%`, maxWidth: '450px' }}
              style={{
                // width: `${stage == 'register' ? '50%' : '60%'}`,
                // maxWidth: '450px',
                backgroundColor: 'transparent',
                border: 'none'
              }}
            >
              {stage == 'register' ? (
                <Image src={require('../../assets/jjq/login/Play.png')} style={{ width: '100%' }} />
              ) : (
                <Image src={require('../../assets/jjq/login/Play_red.png')} style={{ width: '100%' }} />
              )}
            </Button>
          </Col>
        </Row>

        <BGForm reg={stage == 'tandc'} />
        {stage == 'register' ? (
          <>
            <Note2 right='-3%' top='45%' />
            <Tire left='-8%' top='40%' />
          </>
        ) : (
          <>
            <Note2 left='-3%' top='25%' />
            <Tire right='-9%' top='46%' />
          </>
        )}
      </div>
    </div>
  );
};

const DisplayForm = ({ show, type, hide }) => {
  const [data, setData] = React.useState(<></>);

  React.useEffect(() => {
    switch (type) {
      case 'T&C':
        setData(<Terms />);
        break;
      case 'Privacy':
        setData(<Privacy />);
        break;
      case 'Rules':
        setData(<Rules />);
        break;
      case 'DNS':
        setData(<DNS />);
        break;
      default:
        break;
    }
  }, [type]);
  const close = (e) => {
    e?.preventDefault();
    setData(null);
    hide();
  };
  return (
    <div
      className={`${show ? 'd-flex' : 'd-none'} displayContainer`}
      onClick={(e) => close(e)}
      // style={{ backgroundColor: '#00000060', width: '100vw', height: '100vh' }}
    >
      <div className='d-flex justify-content-start'>
        <div className='d-flex justify-content-start align-items-left flex-direction-col'>
          <Col md='12' className='text-center'>
            <Button
              className='btn-sm btn-link btnDispClose'
              varient='transparent'
              onClick={(e) => close(e)}
              style={{ backgroundColor: 'transparent', border: 'none' }}
            >
              <Image src={require('../../assets/jjq/login/Close.png')} fluid />
            </Button>
            <Image
              // style={{ width: '55%', maxWidth: '380px' }}
              className='mb-1  displayHeaderLogo margin-lr-center'
              src={require('../../assets/jjq/login/JJQ-Logo.png')}
              fluid
            />
          </Col>
          <Col md='12' className='text-center'>
            <div className='displayContent'>
              <div
                className='displayScroll'
                onClick={(e) => {
                  // e.preventDefault();
                  e.stopPropagation();
                }}
              >
                {type == 'Rules' ? (
                  <iframe
                    src='https://mecum.statefarmactivations.com/rules.pdf'
                    title='Do Not Sell'
                    // className={styles.tac}
                    style={{ width: '1480px', height: '100%' }}
                  />
                ) : (
                  data
                )}
              </div>
            </div>
          </Col>
        </div>
      </div>
    </div>
  );
};
export default Login;
