import React from "react";

import { NavLink } from "react-router-dom";

// import noPage from "./404.png";

import styles from "./NoPage.module.scss";

const NoPage = (props) => {

  return (
    <div className={styles.container}>
      <div className={styles.imgC}>
        {/* <img className={styles.img} loading="lazy" src={noPage} alt="404" /> */}
        <h1 className={styles.four}>404</h1>
      </div>
      <p className={styles.text}>Sorry, the page you were looking for doesn’t exist. </p>
      <NavLink to="/" className={styles.btn}>
        Home page
      </NavLink>
    </div>
  );
};

export default NoPage;
