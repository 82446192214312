import React, { useEffect } from 'react'
import styles from './Auth.module.scss';
import { Button, TextField } from '@mui/material';
import { useDispatch } from 'react-redux';
import trivia from '../../api/trivia';
import { STORAGE_KEY_AUTH } from '../../api/constants';

function Auth(props) {
    const dispatch = useDispatch();
    const { setAuthenticated } = props;

    const [password, setPassword] = React.useState('');
    const [authError, setAuthError] = React.useState('');

    useEffect(() => {
        const tkn = window.localStorage.getItem(STORAGE_KEY_AUTH);
        if (tkn) {
            dispatch(trivia.checkBA(tkn)).then((response) => {
                setAuthenticated(true);
            }).catch(err => {
                console.log('[ERROR AUTHENTICATING BA CHECK] ', err);
                setAuthenticated(false);
                setAuthError(err.message)
            });
        }
    }, []);

    const authenticate = () => {
        // setAuthError("Unable to do some things because of a longish m asf asd asd asd fasd fasessage")
        setAuthError('');
        dispatch(trivia.authBA({ code: password })).then((response) => {
            window.localStorage.setItem(STORAGE_KEY_AUTH, response.token);
            setAuthenticated(true);
        }).catch(err => {
            console.log('[ERROR AUTHENTICATING] ', err);
            setAuthError(err.message)
        });
    }
    return (
        <div className={styles.authenticationForm}>
            <div className={styles.authenticationFormBody}>
                <div className={styles.authenticationFormHeader}>
                    Please authenticate to continue
                </div>
                {/* <TextField className={styles.authenticationFormInput} label="Username" variant="outlined" value={username} onChange={(e) => setUsername(e.currentTarget.value)} /> */}
                <TextField className={styles.authenticationFormInput} label="Password" variant="outlined" type="password" value={password} onChange={(e) => setPassword(e.currentTarget.value)} onKeyDown={(e) => e.key === 'Enter' && authenticate(e)} />
                {authError && <div className={styles.authenticationFormError}>{authError}</div>}
                <Button className={styles.authenticationFormButton} variant="contained" color="primary" onClick={authenticate}>Authenticate</Button>
            </div>

        </div>
    )
}

export default Auth