import { combineReducers } from 'redux';
import triviaSlice from './triviaSlice';
import userSlice from './userSlice';
import adminSlice from './adminSlice';
import displaySlice from './displaySlice';

const rootReducer = combineReducers({
    admin: adminSlice,
    trivia: triviaSlice,
    user: userSlice,
    display: displaySlice,
});

export default rootReducer;
