import React, { useEffect, useState } from 'react';
import styles from './AdminUI.module.scss';
import Auth from '../../Components/Auth/Auth';
import Menu from '../../Components/Menu/Menu';
import DialogPrompt from '../../Components/RADialog/DialogPrompt';
import {
  Button,
  Fade,
  FormControl,
  InputLabel,
  LinearProgress,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  MenuItem,
  Pagination,
  Select,
  Tooltip,
  Checkbox
} from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import ClearIcon from '@mui/icons-material/Clear';
import CloseIcon from '@mui/icons-material/Close';
import ListAltRoundedIcon from '@mui/icons-material/ListAltRounded';
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

import headerLogo from '../../assets/sf_white_logo.png';
import baqr_bg from '../../assets/qr/baqr_bg.png';
import moment from 'moment';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';

import trivia from '../../api/trivia';
import { DURATIONS, PARAMS, STORAGE_KEY_AUTH } from '../../api/constants';

// const DURATIONS = ["LAST 1 HOUR", "LAST 12 HOURS", "LAST 24 HOURS", "LAST 4 DAYS", "LAST WEEK", "ALL TIME"];
// const PARAMS = ["1 HOUR", "12 HOUR", "24 HOUR", "4 DAY", "1 WEEK", ""];

function AdminUI() {
  const dispatch = useDispatch();

  const [authenticated, setAuthenticated] = useState(false);
  // const [duration, setDuration] = useState(DURATIONS[1]);
  const [fade, setFade] = useState(true);
  // const [timer, setTimer] = useState('');
  // const [qrEmail, setQrEmail] = useState('');
  const [selectedUser, setSelectedUser] = useState(null);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [search, setSearch] = useState('');
  const [loading, setLoading] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [users, setUsers] = useState([]);
  const [searchList, setSearchList] = useState([]);
  const [userList, setUserList] = useState([]);
  const [lastRefresh, setLastRefresh] = useState(null);
  const [events, setEvents] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState('');
  const [datesList, setDatesList] = useState([]);
  const [selectedDate, setSelectedDate] = useState('');
  const [selectedCard, setSelectedCard] = useState('');
  const [errorMessage, setErrorMessage] = useState(null);

  const refreshList = (useLoading = true) => {
    if (!authenticated || loading || !selectedDate) return;
    useLoading && setLoading(true);
    const tkn = window.localStorage.getItem(STORAGE_KEY_AUTH);

    dispatch(trivia.getAdminTargetedLeaderboard(selectedDate.format('YYYY-MM-DD'), tkn))
      .then((response) => {
        console.log('leaderboard resp', response);
        setUsers(response.leaderboard || []);
      })
      .catch((err) => {
        console.log('[ERROR REFRESHING USER LIST] ', err);
      })
      .finally(() => {
        setLoading(false);
        setPage(0);
        setLastRefresh(moment());
      });
  };
  const refreshEventList = () => {
    if (!authenticated || loading) return;
    setLoading(true);
    const tkn = window.localStorage.getItem(STORAGE_KEY_AUTH);
    dispatch(trivia.getEventInfo(tkn))
      .then((response) => {
        console.log('event list resp', response);
        setEvents(response.result || []);
      })
      .catch((err) => {
        console.log('[ERROR REFRESHING EVENT LIST] ', err);
      })
      .finally(() => {
        setLoading(false);
        setLastRefresh(moment());
      });
  };

  useEffect(() => {
    if (authenticated) refreshEventList();
  }, [authenticated]);

  useEffect(() => {
    if (authenticated && selectedEvent && selectedDate) refreshList();
  }, [authenticated, selectedEvent, selectedDate]);

  useEffect(() => {
    const sl = users.filter(
      (user) =>
        !search ||
        user.name.toLowerCase().includes(search.toLowerCase()) ||
        user.displayName.toLowerCase().includes(search.toLowerCase()) ||
        user.email.toLowerCase().includes(search.toLowerCase())
    );
    setSearchList(sl);
  }, [users, search]);

  useEffect(() => {
    const ul = searchList.slice(page * rowsPerPage, (page + 1) * rowsPerPage);
    setUserList(ul);
  }, [searchList, rowsPerPage, page]);

  const downloadLeaderboard = () => {
    const tkn = window.localStorage.getItem(STORAGE_KEY_AUTH);

    // dispatch(trivia.getAdminLeaderboard(PARAMS[DURATIONS.indexOf(duration)] + "&include=ry", tkn)).then((response) => {
    // console.log('resp', response);
    const headers = `rank,displayName,name,email,total,modified,created\n`;
    const csv =
      headers +
      users
        ?.map((user, index) => {
          return `${user.index},${user.displayName},${user.name},${user.email},${user.total},${user.modified},${user.created}`;
        })
        .join('\n');
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.setAttribute('href', url);
    link.setAttribute('download', `leaderboard-${moment().format('MM-DD-YYYY_HH-mm-ss')}.csv`);
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    // }).catch(err => {
    //     console.log('[ERROR DOWNLOADING LEADERBOARD] ', err);
    // });
  };
  const downloadUserList = () => {
    const tkn = window.localStorage.getItem(STORAGE_KEY_AUTH);
    dispatch(trivia.getUserList('&include=ry', tkn))
      .then((response) => {
        const headers = `id,name,email,created\n`;
        const csv =
          headers +
          response.result
            .map((user, index) => {
              return `${index + 1},${user.name},${user.email},${user.created}`;
            })
            .join('\n');
        const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.setAttribute('href', url);
        link.setAttribute('download', `userlist-${moment().format('MM-DD-YYYY_HH-mm-ss')}.csv`);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch((err) => {
        console.log('[ERROR DOWNLOADING USER LIST] ', err);
      });
  };

  useEffect(() => {
    console.log('selected', selectedEvent);
    if (selectedEvent) {
      const startDate = moment(selectedEvent.startDate + 'T00:00:00.000');
      const endDate = moment(selectedEvent.endDate + 'T23:59:59.000');
      const days = moment(endDate).add(1, 'minute').diff(startDate, 'days');
      console.log('days', startDate.format(), endDate.format(), days);
      const li = [];
      for (let i = 0; i < days; i++) {
        // li.push(moment(startDate).add(i, 'days').format('YYYY-MM-DD'));
        li.push(moment(startDate).add(i, 'days'));
      }
      setDatesList(li);
      setSelectedUser(null);
      setSelectedDate('');
      setSelectedCard('');
    }
  }, [selectedEvent]);

  useEffect(() => {
    if (selectedDate) {
      console.log(selectedDate.format('YYYY-MM-DD'));
      setSelectedCard('');
      setSelectedUser(null);
    }
  }, [selectedDate]);

  useEffect(() => {
    if (selectedCard) {
      console.log(selectedCard);
    }
  }, [selectedCard]);

  const sendEmail = (email, cardNumber) => {
    // setErrorMessage('No longer supporting email sending from this interface due to lack of use');
    // return;
    const tkn = window.localStorage.getItem(STORAGE_KEY_AUTH);
    console.log('send email', selectedUser, selectedCard, selectedDate, tkn);
    console.log('s2', loading, email, cardNumber);
    setLoading(true);
    // setTimeout(() => {
    //     setLoading(false);
    // }, 4000);
    dispatch(trivia.sendEmail(selectedUser, selectedCard, tkn))
      .then((response) => {
        console.log('resp', response);
        setEvents((prev) =>
          prev.map((ev, idx) => {
            if (ev.eventId === selectedEvent.eventId) {
              ev.cards.map((crd, cdx) => {
                if (crd.cardId === selectedCard.cardId) {
                  crd.issued = true;
                  crd.issuedList.push({
                    cardId: selectedCard.cardId,
                    userId: selectedUser.userId,
                    name: selectedUser.name,
                    sentAt: moment.utc()
                  });
                  // setSelectedCard(crd);
                }
                return crd;
              });
            }
            return ev;
          })
        );
        // setSelectedCard(prev => { return { ...prev, issued: true, issuedList: [...prev.issuedList, {cardId:selectedCard.cardId,userId:selectedUser.userId, name: selectedUser.name, sentAt: moment.utc() }] } });
      })
      .catch((err) => {
        console.log('[ERROR SENDING EMAIL] ', err);
        setErrorMessage(err.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  //use regex to replace any commas with spaces
  const mc = (d) => {
    return d.replace(/,/g, ' ');
  };

  const mozeusData = () => {
    console.log('mozeusData', selectedEvent);
    if (selectedEvent) {
      //   const startDate = moment(selectedEvent.startDate + 'T00:00:00.000');
      //   const endDate = moment(selectedEvent.endDate + 'T23:59:59.000');
      //   const days = moment(endDate).add(1, 'minute').diff(startDate, 'days');
      //   console.log('days', startDate.format(), endDate.format(), days);
      const tkn = window.localStorage.getItem(STORAGE_KEY_AUTH);
      dispatch(trivia.getMozuesData(selectedEvent.eventId, tkn))
        .then((response) => {
          console.log('resp', response);
          const headers = `EventID,EventEndDate,FirstName,LastName,Email,Address,Address2,City,State,Zip\n`;
          const csv =
            headers +
            response.result
              .filter((user) => user.data.tandc)
              .map((user, index) => {
                return `${mc(selectedEvent.location)},${mc(selectedEvent.endDate)},${mc(user.firstName)},${mc(
                  user.lastName
                )},${mc(user.email)},,,,,`;
              })
              .join('\n');
          const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
          const url = URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.setAttribute('href', url);
          link.setAttribute('download', `StateFarm_Liveramp_${moment().format('MMDDYYYY')}.csv`);
          link.style.visibility = 'hidden';
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        })
        .catch((err) => {
          console.log('[ERROR REFRESHING USER LIST] ', err);
          setErrorMessage('Error downloading data: ' + err.message);
        });
    } else {
      setErrorMessage('No Event Selected');
    }
  };

  const updateUserVis = (user, enabled) => {
    const tkn = window.localStorage.getItem(STORAGE_KEY_AUTH);
    dispatch(trivia.updateUser(user.userId, { enabled }, tkn))
      .then((response) => {
        console.log('resp', response);
        setErrorMessage('User leaderboard visibility updated!');
        setUserList((prev) =>
          prev.map((u, idx) => {
            if (u.userId === user.userId) u.enabled = enabled;
            return u;
          })
        );
      })
      .catch((err) => {
        console.log('[ERROR UPDATING USER VISIBILITY] ', err);
        setErrorMessage('Error updating user visibility: ' + err.message);
      });
  };

  return (
    <Fade in={fade} timeout={700}>
      <div className={styles.admContainer}>
        <Menu menuOpen={menuOpen} setMenuOpen={setMenuOpen} hideModes={true} />
        <img className={styles.baqrBackground} src={baqr_bg} alt='BA QR' />
        {authenticated ? (
          <>
            {/* <div className={styles.timer}>{timer}</div> */}
            <img className={styles.stateLogo} src={headerLogo} onClick={(e) => setMenuOpen(true)} alt='StateFarmlogo' />
            <div className={styles.worker}>
              <div className={classNames(styles.userListContainer, { [styles.disableAll]: loading })}>
                <div className={classNames(styles.userDetails)}>
                  <div className={styles.detailsBody}>
                    <div className={styles.userDetailsBody}>
                      <div className={styles.eventSelection}>
                        <FormControl fullWidth>
                          <InputLabel id='demo-simple-select-label'>Select Event</InputLabel>
                          <Select
                            value={selectedEvent ? selectedEvent.eventId : ''}
                            label={'Select Event '}
                            labelId='demo-simple-select-label'
                            onChange={(e) => {
                              setSelectedEvent(events.find((event) => event.eventId === e.target.value));
                            }}
                          >
                            {events.map((e, i) => {
                              return (
                                <MenuItem key={i} value={e.eventId} data-value={e}>
                                  {e.name}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                        <div className={styles.eventDetails}>
                          {/* <div className={styles.eventTitle}>Name: {selectedEvent?.name}</div> */}
                          <div className={styles.eventTitle}>Location: {selectedEvent?.location}</div>
                          {/* <div className={styles.eventTitle}>Details: {selectedEvent?.details}</div> */}
                          <div className={styles.eventTitle}>Start Date: {selectedEvent?.startDate}</div>
                          <div className={styles.eventTitle}>End Date: {selectedEvent?.endDate}</div>
                        </div>
                        {selectedEvent && (
                          <div className={styles.downloadMozuis}>
                            <Button onClick={mozeusData}>Download Event Data For Mozeus</Button>
                          </div>
                        )}
                        <div className={styles.eventDates}>
                          {datesList.map((date, index) => {
                            return (
                              <div
                                key={index}
                                className={classNames(styles.eventDate, selectedDate === date ? styles.selected : '')}
                                onClick={(e) => {
                                  setSelectedDate(date);
                                }}
                              >
                                {date.format('MMM DD \n YYYY')}
                              </div>
                            );
                          })}
                        </div>
                      </div>

                      <div className={styles.eventCardList}>
                        {selectedEvent && selectedDate && (
                          <div className={styles.listHeader}>
                            Gift Cards Listed By Date{' '}
                            {!selectedCard && (
                              <div className={classNames(styles.prompt, styles.promptD)}>
                                <ArrowDownwardIcon fontSize='small' />
                                Select Card
                              </div>
                            )}
                          </div>
                        )}

                        {selectedEvent &&
                          selectedDate &&
                          selectedEvent?.cards
                            ?.filter((c) => c.date === selectedDate.format('YYYY-MM-DD'))
                            .map((card, index) => {
                              return (
                                <div
                                  key={index}
                                  className={classNames(styles.cardBody, {
                                    [styles.selectedCard]: selectedCard?.cardId === card.cardId
                                  })}
                                >
                                  <div
                                    className={classNames(styles.card, {
                                      [styles.selectedItem]: selectedCard?.cardId === card.cardId
                                    })}
                                    key={index}
                                    onClick={(e) => setSelectedCard(card)}
                                  >
                                    <div className={styles.cardTitle}>{moment(card.date).format('MMM DD')} </div>
                                    <div className={styles.cardNumber}>{card.cardNumber}</div>
                                    <div className={styles.issued}>{card.issued ? 'USED' : 'AVAIL'}</div>
                                    {/* <div className={styles.cardScore}>{selectedUser.cards[card.name] ? selectedUser.cards[card.name].score : 0}</div>
                                                    <div className={styles.cardScore}>{selectedUser.cards[card.name] ? selectedUser.cards[card.name].time : 0}</div> */}
                                  </div>
                                  {selectedCard?.cardId === card.cardId && (
                                    <div className={styles.cardDetails}>
                                      <div className={styles.cardHistory}>
                                        <div className={styles.cardHistoryHeader}>Card Issued/Email Sent History</div>
                                        {card.issuedList.length > 0 ? (
                                          card.issuedList?.map((issued, index) => {
                                            const iss = moment.utc(issued.sentAt).local();
                                            return (
                                              <div key={index} className={styles.cardHistoryItem}>
                                                {issued.name} on {iss.format('MMM DD')} at {iss.format('hh:mm a')}
                                              </div>
                                            );
                                          })
                                        ) : (
                                          <div className={styles.noHistory}>-- No History --</div>
                                        )}
                                      </div>
                                      {selectedUser ? (
                                        <div className={styles.userDetailsHeader}>
                                          <div className={styles.userDetailsHeaderName}>{selectedUser.name}</div>
                                          <div className={styles.userDetailsHeaderEmail}>{selectedUser.email}</div>
                                          <div className={styles.userDetailsHeaderCreated}>
                                            Completed at:{' '}
                                            {moment.utc(selectedUser.modified).local().format('MM-DD-YYYY hh:mm a')}
                                          </div>
                                        </div>
                                      ) : (
                                        <div className={classNames(styles.prompt, styles.promptC)}>
                                          Select User <ArrowForwardIcon />
                                        </div>
                                      )}
                                      {selectedUser && (
                                        <div className={styles.sendEmailContainer}>
                                          <Tooltip
                                            title={`Send Email to ${selectedUser.email}`}
                                            aria-label='send email'
                                          >
                                            <Button
                                              disabled={loading}
                                              variant='contained'
                                              color='inherit'
                                              onClick={(e) => {
                                                // setErrorMessage(
                                                //   'No longer supporting email sending from this interface due to lack of use'
                                                // );
                                                sendEmail(selectedUser.email, selectedCard.cardNumber);
                                              }}
                                            >
                                              Send Email to {selectedUser.name}
                                            </Button>
                                          </Tooltip>
                                        </div>
                                      )}
                                      {/* {selectedUser ? <div className={styles.userDetails}>{selectedUser.name}</div> : <div>Select User To Send Email</div>} */}
                                    </div>
                                  )}
                                </div>
                              );
                            })}
                      </div>
                    </div>
                    {/* {selectedUser && <div className={styles.userDetailsHeader}>
                                    <div className={styles.userDetailsHeaderName}>{selectedUser.displayName}</div>
                                    <div className={styles.userDetailsHeaderEmail}>{selectedUser.email}</div>
                                    <div className={styles.userDetailsHeaderCreated}>Completed at: {moment.utc(selectedUser.modified).local().format("MM-DD-YYYY hh:mm a")}</div>

                                </div>} */}
                  </div>
                </div>
                <div
                  className={classNames(styles.userList, {
                    [styles.halfS_UserList]: selectedEvent != null
                  })}
                >
                  <input
                    className={styles.userSearch}
                    disabled={loading}
                    type='text'
                    placeholder='Search for a user'
                    value={search}
                    onChange={(e) => {
                      setSearch(e.currentTarget.value);
                      setPage(0);
                    }}
                  />
                  <div className={styles.clearSearch}>
                    <Tooltip title='Clear Search Field' placement='top'>
                      <CloseIcon fontSize='small' onClick={(e) => setSearch('')} />
                    </Tooltip>
                  </div>
                  <List className={styles.userListItems}>
                    {!selectedEvent ? (
                      <div className={classNames(styles.prompt, styles.promptA)}>
                        <ArrowBackIcon />
                        Select Event
                      </div>
                    ) : !selectedDate ? (
                      <div className={classNames(styles.prompt, styles.promptB)}>
                        <ArrowBackIcon /> Select Date
                      </div>
                    ) : (
                      <>
                        {loading ? (
                          <ListItem className={styles.userListItem}>
                            <LinearProgress className={styles.progress} />
                          </ListItem>
                        ) : userList.length === 0 ? (
                          <div> No Users For This Date</div>
                        ) : (
                          userList.map((user, index) => {
                            return (
                              <ListItem
                                className={classNames(styles.userListItem, {
                                  [styles.selectedItem]: selectedUser?.email === user.email
                                })}
                                key={index}
                                disablePadding
                              >
                                <ListItemButton className={styles.itemButton} onClick={() => setSelectedUser(user)}>
                                  {/* <ListItemButton className={styles.itemButton} onClick={() => setQrEmail(LINK + user.email)}> */}
                                  <Checkbox
                                    checked={user.enabled}
                                    onChange={(e) => updateUserVis(user, e.target.checked)}
                                  />
                                  <ListItemText className={styles.itemPlace} primary={user.index} />
                                  {/* <input
                                    type='checkbox'
                                    checked={user.enabled}
                                    onChange={(e) => updateUserVis(user, e.target.checked)}
                                  /> */}
                                  <ListItemText className={styles.itemScore} primary={user.total} />
                                  <ListItemText
                                    className={styles.itemName}
                                    primary={`${user.name} [${user.displayName}]`}
                                    secondary={user.email}
                                  />
                                  {/* <ListItemText className={styles.itemEmail} primary={user.email} /> */}
                                  <ListItemText
                                    className={styles.itemCreated}
                                    primary={moment.utc(user.created).local().format('MM-DD  hh:mm a')}
                                  />
                                </ListItemButton>
                              </ListItem>
                            );
                          })
                        )}
                      </>
                    )}
                  </List>
                  <div className={styles.pagingFooter}>
                    {/* add a rowsPerPage choice box with 10, 20, 50, and 100 as options */}
                    <div className={styles.rowSelect}>
                      <div className={styles.pagingFooterText}>Rows/page:</div>
                      <select
                        className={styles.pagingFooterSelect}
                        value={rowsPerPage}
                        onChange={(e) => setRowsPerPage(e.currentTarget.value)}
                      >
                        <option value={10}>10</option>
                        <option value={20}>20</option>
                        <option value={50}>50</option>
                        <option value={100}>100</option>
                      </select>
                      <div className={styles.pagingFooterText}>[{users?.length}]</div>
                      {/* <div className={styles.pagingFooterText}>Total: {searchList.length}/{users.length}</div> */}
                    </div>
                    <div className={styles.controlPanel}>
                      {/* <Tooltip title="Select List Time period" placement="top">
                                        <select value={duration} onChange={(e) => setDuration(e.currentTarget.value)}>
                                            {DURATIONS.map((d, i) => {
                                                return <option key={i} value={d}>{d}</option>
                                            })}
                                        </select>
                                    </Tooltip> */}
                      <Tooltip title='Refresh List' placement='top'>
                        <RefreshIcon fontSize='small' onClick={refreshList} />
                      </Tooltip>
                      <Tooltip title='Clear Selection' placement='top'>
                        <ClearIcon fontSize='small' onClick={() => setSelectedUser(null)} />
                      </Tooltip>
                      <Tooltip title={`Download Current Leaderboard`} placement='top'>
                        <ListAltRoundedIcon fontSize='small' onClick={() => downloadLeaderboard()} />
                      </Tooltip>
                      <Tooltip title={`Download User List For ALL TIME`} placement='top'>
                        <PeopleOutlineIcon fontSize='small' onClick={() => downloadUserList()} />
                      </Tooltip>
                    </div>
                    <Pagination
                      size='small'
                      className={styles.pagingObject}
                      count={Math.ceil(searchList.length / rowsPerPage)}
                      page={page + 1}
                      onChange={(e, p) => setPage(p - 1)}
                    />
                  </div>
                  {/* <div className={styles.listDisclaimer}>Only seeing users who registered within {duration}</div> */}
                </div>
              </div>
            </div>
          </>
        ) : (
          <Auth authenticated={authenticated} setAuthenticated={setAuthenticated} />
        )}
        <DialogPrompt message={errorMessage} close={() => setErrorMessage(null)} />
      </div>
    </Fade>
  );
}

export default AdminUI;
