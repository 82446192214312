import { createSlice } from "@reduxjs/toolkit";

export const REG_TYPES = { NEW: 'newuser', RETURNING: 'returninguser' };
const init = {
    appState: 'mode1',
    regType: REG_TYPES.NEW, // 'newuser' or 'returninguser'
    user: null,
    team: null,
    token: null,
    error: null,
};

const userSlice = createSlice({
    name: 'user',
    initialState: init,
    reducers: {
        setUser: (state, action) => {
            state.user = action.payload;
        },
        setTeam: (state, action) => {
            // console.log('TEAM SET: ', action.payload);
            state.team = action.payload;
        },
        setToken: (state, action) => {
            // console.log('TOKEN SET: ', action);
            state.token = action.payload;
        },
        setUserError: (state, action) => {
            // console.log('SET ERROR', state, action);
            state.error = action.payload;
        },
        setAppState: (state, action) => {
            // console.log('SET APP STATE', state, action);
            state.appState = action.payload;
        },
        setRegistrationType: (state, action) => {
            // console.log('SET REG TYPE', state, action);
            state.regType = action.payload;
        },
        clear: (state) => {
            // console.log('CLEARING', state.appState)
            return { ...init, appState: state.appState }
        },

    }
});

export const getUserError = state => state.user.error;
export const getUserToken = state => state.user.token;
export const getTeam = state => state.user.team;
export const selectAppState = state => state.user.appState;
export const selectRegType = state => state.user.regType;

export const { setUsers, setTeam, setToken, setUserError, setAppState, setRegistrationType, clear } = userSlice.actions;
export default userSlice.reducer;