import { addToScore, setQuestions, setScore, setSessionId, setTriviaError } from '../store/reducers/triviaSlice';

import SendRequest from './sendRequest';

class Trivia extends SendRequest {
  static getTriviaQuestions(token) {
    return (dispatch) => {
      return new Promise((resolve, reject) => {
        this.sendGet('/api/sf/trivia', token)
          .then((result) => {
            if (result.status === 200) {
              console.log('TRIVIA RECEIVED: ', result.data);
              dispatch(setSessionId(result.data.sessionId));
              dispatch(setQuestions(result.data.questions));
              // dispatch(setTeam(result.data.team));
              // dispatch(setToken(result.data.token));
              resolve(result.data);
            } else {
              console.log('ERROR RETRIEVING TRIVIA', result);
              dispatch(setTriviaError(result.data.message));
              reject(result.data);
            }
          })
          .catch((err) => {
            console.log('ERROR RETRIEVING TRIVIA', err);
            reject(err);
          });
      });
    };
  }

  static reportScore(token, streamId, roundId, questionId, optionId, points, sessionId) {
    var data = { streamId, roundId, questionId, optionId, points, sessionId };
    console.log('DATA: ', data);
    return (dispatch) => {
      return new Promise((resolve, reject) => {
        this.sendPost('/api/sf/trivia/response', data, token)
          .then((result) => {
            if (result.status === 200) {
              console.log('SCORE REPORTED: ', result.data);
              // dispatch(addToScore(points));
              resolve(result.data);
            } else {
              console.log('ERROR REPORTING SCORE', result);
              // dispatch(setTriviaError(result.data.message));
              reject(result.data);
            }
          })
          .catch((err) => {
            console.log('ERROR REPORTING SCORE', err);
            reject(err);
          })
          .finally(() => {
            dispatch(addToScore(points));
          });
      });
    };
  }

  static getLeaderboard(token, param = '') {
    return (dispatch) => {
      return new Promise((resolve, reject) => {
        this.sendGet('/api/sf/trivia/leaderboard' + (param ? `?limit=${param}` : ''), token)
          .then((result) => {
            if (result.status === 200) {
              console.log('LEADERBOARD RECEIVED: ', result.data);
              // dispatch((result.data));
              // dispatch(setTeam(result.data.team));
              // dispatch(setToken(result.data.token));
              resolve(result.data);
            } else {
              console.log('ERROR RETRIEVING TRIVIA', result);
              dispatch(setTriviaError(result.data.message));
              reject(result.data);
            }
          })
          .catch((err) => reject(err));
      });
    };
  }
  static getAdminLeaderboard(param = '', token) {
    return (dispatch) => {
      return new Promise((resolve, reject) => {
        this.sendGet('/api/sf/mecum/trivia/leaderboard' + (param ? `?limit=${param}` : ''), token)
          .then((result) => {
            if (result.status === 200) {
              // console.log("LEADERBOARD RECEIVED: ", result.data);
              // dispatch((result.data));
              // dispatch(setTeam(result.data.team));
              // dispatch(setToken(result.data.token));
              resolve(result.data);
            } else {
              console.log('ERROR RETRIEVING TRIVIA', result);
              dispatch(setTriviaError(result.data.message));
              reject(result.data);
            }
          })
          .catch((err) => reject(err));
      });
    };
  }
  static getAdminTargetedLeaderboard(date, token) {
    return (dispatch) => {
      return new Promise((resolve, reject) => {
        this.sendGet(`/api/sf/mecum/trivia/leaderboard/target?date=${date}`, token)
          .then((result) => {
            if (result.status === 200) {
              // console.log("LEADERBOARD RECEIVED: ", result.data);
              // dispatch((result.data));
              // dispatch(setTeam(result.data.team));
              // dispatch(setToken(result.data.token));
              resolve(result.data);
            } else {
              console.log('ERROR RETRIEVING TRIVIA', result);
              dispatch(setTriviaError(result.data.message));
              reject(result.data);
            }
          })
          .catch((err) => reject(err));
      });
    };
  }
  static sendEmail(user, card, token) {
    var data = { user, card };
    console.log('DATA: ', data);
    return (dispatch) => {
      return new Promise((resolve, reject) => {
        this.sendPost('/api/sf/mecum/data/email', data, token)
          .then((result) => {
            if (result.status === 200) {
              console.log('EMAIL SENT: ', result.data);
              // dispatch(addToScore(points));
              resolve(result.data);
            } else {
              console.log('ERROR SENDING EMAIL', result);
              // dispatch(setTriviaError(result.data.message));
              reject(result.data);
            }
          })
          .catch((err) => {
            console.log('ERROR SENDING EMAIL CATCH', err);
            reject(err);
          })
          .finally(() => {
            // dispatch(addToScore(points));
          });
      });
    };
  }

  static getEventInfo(token) {
    return (dispatch) => {
      return new Promise((resolve, reject) => {
        this.sendGet('/api/sf/mecum/data/parts', token)
          .then((result) => {
            if (result.status === 200) {
              // console.log("LEADERBOARD RECEIVED: ", result.data);
              // dispatch((result.data));
              // dispatch(setTeam(result.data.team));
              // dispatch(setToken(result.data.token));
              resolve(result.data);
            } else {
              console.log('ERROR RETRIEVING TRIVIA', result);
              dispatch(setTriviaError(result.data.message));
              reject(result.data);
            }
          })
          .catch((err) => reject(err));
      });
    };
  }
  static authBA(data) {
    return (dispatch) => {
      return new Promise((resolve, reject) => {
        this.sendPost('/api/sf/mecum/otc/chk', data)
          .then((result) => {
            if (result.status === 200) {
              console.log('Auth: ', result.data);
              // dispatch(addToScore(points));
              resolve(result.data);
            } else {
              console.log('ERROR AUTHING', result);
              // dispatch(setTriviaError(result.data.message));
              reject({ message: result });
            }
          })
          .catch((err) => {
            console.log('ERROR AUTHING2', err);
            reject({ message: err });
          });
      });
    };
  }
  static checkBA(token) {
    return (dispatch) => {
      return new Promise((resolve, reject) => {
        this.sendPost('/api/sf/mecum/otc/dchk', {}, token)
          .then((result) => {
            if (result.status === 200) {
              console.log('Auth: ', result.data);
              // dispatch(addToScore(points));
              resolve(result.data);
            } else {
              console.log('ERROR AUTHING', result);
              // dispatch(setTriviaError(result.data.message));
              reject({ message: result });
            }
          })
          .catch((err) => {
            console.log('ERROR AUTHING2', err);
            reject({ message: err });
          });
      });
    };
  }
  static getUserList(param, token) {
    return (dispatch) => {
      return new Promise((resolve, reject) => {
        this.sendGet('/api/sf/mecum/ulist' + (param ? `?limit=${param}` : ''), token)
          .then((result) => {
            if (result.status === 200) {
              console.log('RECEIVED USERS: ', result.data);
              resolve(result.data);
            } else {
              console.log('ERROR RETRIEVING TRIVIA', result);
              dispatch(setTriviaError(result.data.message));
              reject(result.data);
            }
          })
          .catch((err) => reject(err));
      });
    };
  }
  static updateUser(userId, data, token) {
    return (dispatch) => {
      return new Promise((resolve, reject) => {
        this.sendPost(`/api/sf/mecum/user/${userId}`, data, token)
          .then((result) => {
            if (result.status === 200 || result.status === 204) {
              console.log('USER UPDATED: ', result.data);
              resolve(result.data);
            } else {
              console.log('ERROR UPDATING USER', result);
              dispatch(setTriviaError(result.data.message));
              reject(result.data);
            }
          })
          .catch((err) => reject(err));
      });
    };
  }

  static getMozuesData(eventId, token) {
    return (dispatch) => {
      return new Promise((resolve, reject) => {
        this.sendGet(`/api/sf/mecum/mlist?eventId=${eventId}`, token)
          .then((result) => {
            if (result.status === 200) {
              console.log('RECEIVED USERS: ', result.data);
              resolve(result.data);
            } else {
              console.log('ERROR RETRIEVING TRIVIA', result);
              dispatch(setTriviaError(result.data.message));
              reject(result.data);
            }
          })
          .catch((err) => reject(err));
      });
    };
  }
}
export default Trivia;
