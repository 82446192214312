import React from 'react';
import { Image, Button } from 'react-bootstrap';
// import { useAuth } from '../../components/Auth/IMTAuthProvider';
import { useNavigate } from 'react-router-dom';
import styles from './JJQ.module.scss';
import './JJQ.css';
import 'animate.css';
import Fade from '@mui/material/Fade';
import { API_DOMAIN, API_DOMAIN_KEY, API_TOKEN_KEY, GAME_REDIRECT, GAME_REDIRECT_KEY } from '../../api/constants';
// https://animate.style/#attention_seekers
// import { API_DOMAIN, API_DOMAIN_KEY, API_TOKEN_KEY, GAME_REDIRECT, GAME_REDIRECT_KEY } from '../../api/constants';

function JJQ() {
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(false);
  const [fade, setFade] = React.useState(true);
  const user = false;
  const tokens = false;
  //   const { user, tokens, loading } = useAuth();

  React.useEffect(() => {
    localStorage.setItem(API_DOMAIN_KEY, API_DOMAIN);
    localStorage.setItem(GAME_REDIRECT_KEY, GAME_REDIRECT);
    localStorage.removeItem(API_TOKEN_KEY);
  }, []);

  const go = (e) => {
    e?.preventDefault();
    setFade(false);
    setTimeout(() => {
      // if (user && tokens) navigate(`/jjq/play`, { state: { from: 'home' } });
      // else navigate('/jjq/login');
      navigate('/jjq/login');
    }, 700);
  };

  return (
    <div className='background-image'>
      <Fade in={fade} timeout={700}>
        <div className='container'>
          <div
            // className='d-flex justify-content-center align-items-center'
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
              position: 'relative',
              zIndex: 100
            }}
          >
            <div className='text-center flex-direction-col' sm='12' md='12' style={{ textAlign: 'center' }}>
              <Image
                className='homeHeaderLogo'
                src={require('../../assets/jjq/JJQ-Logo.png')}
                alt='JJQ-Logo'
                fluid
                onClick={go}
              />
              {/* <div className='text-center flex-direction-col' sm='12' md='12'></div> */}
              <Button
                disabled={loading}
                className='mt-1 animate__animated animate__pulse animate__infinite animate__slow homePlayButton'
                onClick={go}
                variant='transparent'
                size='sm'
                style={{ backgroundColor: 'transparent', border: 'none' }}
              >
                <Image src={require('../../assets/jjq/home/Play.png')} fluid />
              </Button>
            </div>
          </div>
          <div
            className='d-none d-sm-none d-md-none d-lg-block d-xl-block animate__animated animate__headShake animate__delay-3s homeJake'
            style={{
              position: 'fixed',
              bottom: '6%',
              right: '5%',
              width: '20vw',
              zIndex: 1
            }}
          >
            <Image src={require('../../assets/jjq/Jake.png')} fluid />
          </div>
        </div>
      </Fade>
    </div>
  );
}

export default JJQ;
