import React, { useEffect } from 'react'
import styles from './Rules.module.scss';
// import pdf from '../../assets/rules/MECUM_2023_TRIVIA_RULES.pdf';
// import { Document } from 'react-pdf';

function Rules() {
    useEffect(() => {
        window.location = 'rules.pdf'
    }, []);
    return (<div className={styles.rulesContainer}>
        <iframe src={'rules.pdf'} title="Mecum trivia rules pdf" width="100%" height="100%"></iframe>
    </div>
    )
}

export default Rules