import React from 'react';
import styles from './TacDisplay.module.scss';
import classNames from 'classnames';
import QR from '../../assets/Terms_QR.png';

function Terms() {
  return (
    <div className={classNames(styles.tacObject, styles.terms)}>
      <h1>State Farm® Terms of Use for statefarm.com®</h1>
      These statefarm.com Terms of Use outline the terms and conditions relating to your use of statefarm.com and
      provide additional information about State Farm Mutual Automobile Insurance Company, its affiliates and
      subsidiaries (State Farm). These Terms of Use are applicable to your use of this site regardless of how you
      accessed it. If you do not wish to be bound by these Terms of Use, please discontinue using and accessing this
      site immediately.
      <h1>Privacy Policy</h1>
      To receive information about State Farm's Privacy Policy, please visit our Notice of Privacy Policy.
      <h1>Your Use of This Site</h1>
      You may not use this site to engage in any illegal activity. You may not use this site to engage in conduct which
      is defamatory, libelous, threatening or harassing or that infringes on a third party's intellectual property or
      other proprietary rights. In the event you engage in activity that is illegal, defamatory, libelous, threatening,
      or harassing, State Farm reserves the right to disclose any information you provide through the use of this site
      or other State Farm owned electronic sites or applications to law enforcement authorities as deemed appropriate in
      the sole discretion of State Farm. You agree that any information you provide through this site will be truthful
      and accurate. State Farm reserves the right to require a customer change their User ID or answers to security
      questions if they are deemed inappropriate at State Farm's sole discretion and judgment.
      <h1>Emails to State Farm Addresses</h1>
      The State Farm email system is a private email system intended only for purposes authorized by State Farm. You are
      not authorized to send numerous unsolicited commercial email messages to any email addresses @statefarm.com. State
      Farm reserves the right to take actions as may be necessary to prevent misuse of its email system by unauthorized
      parties.
      <h1>Disclaimer of Warranties</h1>
      Any information, services, materials, software, calculators or other items on this site are provided "as is"
      without any warranty of any kind, either expressed or implied, including without limitation the implied warranties
      of merchantability and fitness for a particular purpose. State Farm does not warrant that this site will be
      uninterrupted or error free. Any risk arising out of use or performance of the information, services, materials,
      software, calculators or other items remains entirely with you. Under no circumstances will State Farm be liable
      for any damages whatsoever arising out of the use or performance of the information, services, materials,
      software, calculators or other items.
      <h1>Secured Areas/Passwords</h1>
      Some portions of this site are restricted and require authorization for access. Unauthorized use of or access to
      these areas is prohibited. Actual or attempted unauthorized use of or access to such areas may result in criminal
      and/or civil prosecution. Attempts to access such areas without authorization may be viewed, monitored and
      recorded and any information obtained may be given to law enforcement organizations in connection with any
      investigation or prosecution of possible criminal activity on this system. If you are not an authorized user of
      such areas or do not consent to continued monitoring, you should not attempt to access such areas. If you are an
      authorized user of any restricted area, you are responsible to maintain the security/confidentiality of your
      password. DO NOT SHARE YOUR PASSWORD WITH ANYONE. State Farm will not ask you for your password. If you know or
      suspect that your password has been compromised, change your password immediately. If you suspect any unauthorized
      activity related to your account, you should contact State Farm. State Farm will not be responsible if you do not
      properly secure your password or if you choose to share your password with anyone else. In order to protect your
      personal information State Farm encourages you to change your password every six months.
      <h1>State Farm Intellectual Property Rights</h1>
      The State Farm three oval logo, the term "State Farm" and all other State Farm trademarks are the intellectual
      property of the State Farm Mutual Automobile Insurance Company and its affiliates and subsidiaries. Intellectual
      property rights of third parties may apply where noted. Except where otherwise indicated, all materials on this
      site including but not limited to graphics, text, software, audio, video, and files, are the property of State
      Farm and are protected by copyright or other intellectual property laws. State Farm reserves all rights to its
      intellectual property. You may print out a single copy of pages on this site for your personal, non-commercial use
      provided that all copyright, trademark or other notices are retained. ALL OTHER USES ARE PROHIBITED. You are not
      authorized to use any portion of this site or any other State Farm intellectual property on any other site, in the
      meta-tags of any other site or in any other materials. You may not duplicate, distribute, display, perform, create
      derivative works, or otherwise use any of this site's content or frame this site within any other site.
      <h1>Links to/From Other Sites</h1>
      For your convenience, State Farm may provide links to other Internet sites that State Farm does not maintain. You
      should not interpret any link to/from other sites as indicating that State Farm sponsors or endorses the sites or
      their materials or that the sites are affiliated with State Farm in any manner. State Farm is not responsible for
      anything contained on such sites and makes no warranties or representations about the contents, products or
      services offered on such sites. State Farm is not responsible for and makes no warranties or representations about
      the content, products or services offered on any sites that you may elect to use in connection with this site.
      <h1>Availability of Products and Services</h1>
      State Farm does business in certain states in the United States of America and certain provinces in Canada. Home
      offices are located in Bloomington, Illinois unless otherwise indicated. Not all of the products or services
      described on this site are available in all states or provinces. You may not be eligible for all products and
      services and State Farm reserves the right to determine such eligibility as permitted by law. Where noted, certain
      products or services may be subject to specific terms, conditions, representations or agreements. The portions of
      this site related to such products and services provide additional information about terms, conditions
      representations, agreements, eligibility and availability. State Farm's securities products are distributed by
      State Farm VP Management Corp., One State Farm Plaza, Bloomington, Illinois 61710-0001. These products are not
      FDIC Insured, are not bank guaranteed are subject to investment risk, including possible loss of principal. State
      Farm VP Management Corp. is a separate entity from those State Farm entities which provide banking and insurance
      products. For more information, potential investors should obtain a prospectus for the mutual funds at
      www.statefarm.com/IPS/prospectus or for variable products at
      www.statefarm.com/insurance/life/variable-universal-life/prospectuses-reports.
      <h1>Other Agreements</h1>
      These Terms of Use apply to your use of this site and do not change or alter any other contract or agreement
      between you and State Farm.
      <h1>Insurance Quotes/Coverages</h1>
      At this time, on-line quotes may not be available for all states or provinces, products, services or coverage
      selections. For additional information about products, services or coverage selections otherwise offered by State
      Farm, please contact a State Farm agent. All quotes generated by this site are estimates based upon the
      information you provided and are not a contract, binder, or agreement to extend insurance coverage. Any coverage
      descriptions provided on this site are general descriptions of available coverages and are not a statement of
      contract. To obtain coverage you must submit an application to State Farm. All applications are subject to
      underwriting approval. Coverages and availability may vary by state or province; and additional minimum coverage
      limits may be available in your state. For additional information, please contact a State Farm agent.
      <h1>Online Claims Submissions</h1>
      If you are a State Farm customer, you may elect to report an insurance claim online where available. Your
      submission does not commit State Farm to coverage for this loss. Information you submit regarding your insurance
      policy and the loss is subject to review and verification. State Farm reserves the right to request additional
      information prior to reaching a decision on the claim. A claim representative will be communicating with you
      regarding your claim. All policy provisions contained in your policy remain in effect. If you have any questions
      concerning the coverage afforded by your policy, please contact your State Farm agent.
      <h1>SMS Texting Terms and Conditions</h1>
      State Farm offers both one time text message programs as well as subscription text services. For more information,
      please visit SMS Texting Terms and Conditions.
      <h1>Changes to This Document</h1>
      The content of this site and of these Terms of Use are subject to change without prior notice.
      <h1>Applicable Law/Severability</h1>
      These Terms of Use shall be governed by and interpreted according to the laws of the State of Illinois without
      respect to any conflict of laws provisions. If any portion of these Terms of Use is unlawful, void or
      unenforceable, it shall not affect the validity or enforceability of any other provision.
      <h1>Additional Information About State Farm</h1>
      <h2>Insurance Licensing information - General</h2>
      State Farm Mutual Automobile Insurance Company is organized under the laws of the State of Illinois and licensed
      in all 50 states, the District of Columbia, and the provinces of Alberta, New Brunswick, and Ontario. The company
      is principally engaged in writing automobile insurance. In the U.S. the company also writes long term care,
      disability income, Medicare Supplement and supplemental health insurance, and commercial auto insurance. NAIC
      number: 25178 State Farm Fire and Casualty Company, a wholly owned subsidiary of State Farm Mutual Automobile
      Insurance Company, is organized under the laws of the State of Illinois and licensed in all 50 states, the
      District of Columbia, and the provinces of Alberta, New Brunswick, and Ontario. The company is principally engaged
      in writing personal lines and writing property and casualty lines of insurance consisting mainly of homeowners and
      commercial multiple peril. In the U.S. the company also writes boat owners, personal liability umbrella,
      farm-ranch, commercial auto, commercial umbrella, professional and specialty, workers compensation, inland marine
      & mobile property, surety and fidelity bonds. NAIC number: 25143 State Farm General Insurance Company, a wholly
      owned subsidiary of State Farm Mutual Automobile Insurance Company, is organized under the laws of the State of
      Illinois and is licensed in all states except Rhode Island, Connecticut, and Massachusetts and primarily writes in
      California. Its principal focus is on personal lines and writing property and casualty lines of insurance
      consisting mainly of homeowners and commercial multiple peril. The company also writes boat owners, personal
      liability umbrella, farm-ranch, commercial umbrella, commercial auto, professional and specialty, workers
      compensation, inland marine & mobile property, surety and fidelity bonds. NAIC number: 25151 State Farm Indemnity
      Company, a wholly owned subsidiary of State Farm Mutual Automobile Insurance Company, is organized under the laws
      of the State of Illinois. Although licensed in both Illinois and New Jersey, it is solely engaged in the writing
      of automobile insurance in New Jersey. NAIC number: 43796 State Farm Guaranty Insurance Company, a wholly owned
      subsidiary of State Farm Indemnity Company, is organized under the laws of the State of Illinois. Although
      licensed in both Illinois and New Jersey, it is solely engaged in the writing of automobile insurance in New
      Jersey. NAIC number: 12251 State Farm Florida Insurance Company*, a wholly owned subsidiary of State Farm Mutual
      Automobile Insurance Company, is organized under the laws of the State of Florida and licensed in Florida and
      Illinois. The company writes property insurance in the State of Florida. NAIC number: 10739 State Farm Life
      Insurance Company, a wholly owned subsidiary of State Farm Mutual Automobile Insurance Company, is organized under
      the laws of the State of Illinois and is licensed in the District of Columbia, all states except Massachusetts,
      New York, and Wisconsin. The company primarily writes individual life and annuity products. NAIC number: 69108
      State Farm Life and Accident Assurance Company, a wholly owned subsidiary of State Farm Mutual Automobile
      Insurance Company, is organized under the laws of the State of Illinois and licensed in Connecticut, Illinois, New
      York, and Wisconsin. The company primarily writes individual life and annuity products. NAIC number: 69094 State
      Farm Annuity and Life Insurance Company, a wholly owned subsidiary of State Farm Life Insurance Company, is
      organized under the laws of the State of Illinois and licensed in all states except Connecticut, Florida, Iowa,
      Maine, Massachusetts, Minnesota, New Hampshire, New York, North Carolina, Oklahoma, Pennsylvania, Rhode Island,
      Texas, and Wisconsin. NAIC number: 94498
      <h2>Insurance Licensing Information - Texas</h2>
      State Farm Mutual Automobile Insurance Company is organized under the laws of the State of Illinois and licensed
      in all 50 states, the District of Columbia, and the provinces of Alberta, New Brunswick, and Ontario. The company
      is principally engaged in writing automobile insurance. In the U.S. the company also writes long term care,
      disability income, Medicare Supplement and supplemental health insurance. NAIC number: 25178 State Farm Fire and
      Casualty Company, a wholly owned subsidiary of State Farm Mutual Automobile Insurance Company, is organized under
      the laws of the State of Illinois and licensed in all 50 states, the District of Columbia, and the provinces of
      Alberta, New Brunswick, and Ontario. The company is principally engaged in writing personal lines and writing
      property and casualty lines of insurance consisting mainly of homeowners and commercial multiple peril, including
      boat owners, personal liability umbrella policies, marine, commercial inland marine and mobile property,
      commercial auto, farm-ranch, commercial umbrella, surety and fidelity bonds and workers compensation. NAIC number:
      25143 State Farm Life Insurance Company, a wholly owned subsidiary of State Farm Mutual Automobile Insurance
      Company, is organized under the laws of the State of Illinois and is licensed in the District of Columbia, all
      states except Massachusetts, New York, and Wisconsin. The company primarily writes individual life and annuity
      products. NAIC number: 69108 State Farm Lloyds** is an association of underwriters operating under a Lloyds Plan
      as provided under Chapter 941 of the Texas Insurance Code and is under common management with State Farm Mutual
      Automobile Insurance Company. State Farm Lloyds is principally engaged in writing homeowners and multiple peril
      insurance, including homeowners, renters, boat owners, personal liability umbrella, commercial multi-peril,
      farm-ranch, commercial umbrella, commercial auto, professional and specialty, inland marine & mobile property in
      Texas. NAIC number: 43419 State Farm Lloyds, Inc.**, is a Texas corporation and is wholly owned by State Farm
      Mutual Automobile Insurance Company. It is the Attorney-in-Fact for State Farm Lloyds. State Farm County Mutual
      Insurance Company of Texas** is a county mutual company organized under the laws of the State of Texas and is
      under common management with State Farm Mutual Automobile Insurance Company. The company is principally engaged in
      writing automobile, Commercial Auto and personal inland marine personal articles insurance in Texas. NAIC number:
      26816
      <h1>Language Notice</h1>
      State Farm insurance policies, agreements, applications, forms and required notices are written in English. This
      document has been translated for your convenience. In the event of any difference in interpretation, the English
      language version controls.
      <h1>Other Companies</h1>
      State Farm VP Management Corp., One State Farm Plaza, Bloomington, IL 61710-0001.
      <br />
      All companies are domiciled in Bloomington Illinois except where noted: * State of Florida ** State of Texas
      <br />
      <h2>View on State Farm Website</h2>
      <img src={QR} alt='QR Code' />
      www.statefarm.com/customer-care/disclosures/terms-of-use
      <br />
      <br />
      *Some links have been removed for this experience, if you wish to view the full experience please visit the State
      Farm website at https://www.statefarm.com/
    </div>
  );
}

export default Terms;
