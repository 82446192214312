import React from 'react'
import { Button, Dialog, DialogActions, DialogContent } from '@mui/material'
import './DialogPrompt.css';

const DialogPrompt = (props) => {
    const { message, close } = props;
    return (
        <Dialog fullWidth={true} className="dialog-prompt-container" open={message ? true : false} onClose={close}>
            <DialogContent>{message}</DialogContent>
            <DialogActions>
                <div></div>
                <Button onClick={close}>Close</Button>
            </DialogActions>
            <svg className="clip-path">
                <clipPath id="start-prompt-dialog" clipPathUnits="objectBoundingBox"><path d="M0 0.0574781L1 0L0.974229 1L0.0283576 0.954032L0 0.0574781Z"></path></clipPath>
            </svg>
        </Dialog>
    )
}

export default DialogPrompt

