import { createSlice } from "@reduxjs/toolkit";

const init = { connected: false, error: null };

const adminSlice = createSlice({
    name: 'admin',
    initialState: init,
    reducers: {
        setConnected(state, action) {
            console.log('SETTING ADMIN CONNECTED: ', state, action);
            state.connected = action.payload;
        },
        setAdminError(state, action) {
            state.error = action.payload;
        },
        clear: () => init,
    }
})

export const getConnected = state => state.adimin.connected;
export const getAdminError = state => state.adimin.error;
export const { setConnected, setAdminError, clear } = adminSlice.actions;
export default adminSlice.reducer;