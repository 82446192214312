import { setTeam, setToken, setUserError } from '../store/reducers/userSlice';
import SendRequest from './sendRequest';

class Register extends SendRequest {
  static registerUser(user) {
    // return (dispatch) => {
    return new Promise((resolve, reject) => {
      console.log('registering', user);
      this.sendNoAuthPost('/api/user/register', user)
        .then((result) => {
          console.log('USER REGISTERED: ', result);
          if (result.status === 200) {
            console.log('USER REGISTERED: ', result);
            // dispatch(setTeam(result.data.team));
            // dispatch(setToken(result.data.token));
            resolve(result.data);
          } else {
            console.log('ERROR REGISTERING', result);
            // dispatch(setUserError(result.data.message));
            reject(result.data);
          }
        })
        .catch((err) => {
          console.log('ERROR REGISTERING C', err);
          // dispatch(setUserError(err.message));
          reject(err);
        });
    });
    // };
  }
  static checkUser(user) {
    // return (dispatch) => {
    return new Promise((resolve, reject) => {
      console.log('checking', user);
      this.sendNoAuthPost('/api/user/check', user)
        .then((result) => {
          console.log('USER CHECKED: ', result);
          if (result.status === 200) {
            console.log('USER CHECKED GOOD: ', result);
            // dispatch(setTeam(result.data.team));
            // dispatch(setToken(result.data.token));
            resolve(result.data);
          } else {
            console.log('ERROR CHECKING', result);
            // dispatch(setUserError(result.data.message));
            reject(result.data);
          }
        })
        .catch((err) => {
          console.log('ERROR CHECKING CATCH', err);
          // dispatch(setUserError(err.message));
          reject(err);
        });
    });
    // };
  }
  static setError(error) {
    return (dispatch) => {
      return new Promise((resolve, reject) => {
        dispatch(setUserError(error));
        resolve();
      });
    };
  }
}
export default Register;
