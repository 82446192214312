import { createSlice } from "@reduxjs/toolkit";

const init = { cycleTime: 10, mediaList: [] };

const displaySlice = createSlice({
    name: "display",
    initialState: init,
    reducers: {
        setCycleTime(state, action) {
            state.cycleTime = action.payload;
        },
        setMediaList(state, action) {
            state.mediaList = action.payload;
        },
        clear: () => { return init },
    },
});

export const getMediaList = (state) => state.display.mediaList;
export const getCycleTime = (state) => state.display.cycleTime;

export const { setCycleTime, setMediaList } = displaySlice.actions;
export default displaySlice.reducer;
