import React from 'react';
import { connect } from 'react-redux';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import styles from './App.module.scss';

// import Home from '../Pages/Home/Home';
import Leaderboard from '../Pages/Leaderboard/Leaderboard';
import NoPage from '../Pages/NoPage/NoPage';
// import Registration from '../Pages/Registration/Registration';
// import Done from '../Pages/Trivia/Done/Done';
// import Trivia from '../Pages/Trivia/Trivia';
import AdminUI from '../Pages/AdminUI/AdminUI';

// import useAppStateStorage from '../hooks/useAppStateStorage';
import RotatingLeaderboard from '../Pages/RotatingLeaderboard/RotatingLeaderboard';
import PhotoRegistration from '../Pages/PhotoRegistration/PhotoRegistration';
import PhotoRegHome from '../Pages/PhotoRegistration/PhotoRegHome';
import PhotoRegThanks from '../Pages/PhotoRegistration/PhotoRegThanks';
import BAQR from '../Pages/BAQR/BAQR';
import Base from '../Pages/Base/Base';
import Rules from '../Pages/Rules/Rules';

// import 'bootstrap/dist/css/bootstrap.min.css';
import '../styles/boots.css';
import JJQ from '../Pages/JJQ/JJQ';
import Login from '../Pages/JJQ/Login';
import Play from '../Pages/JJQ/Play';
import Logout from '../Pages/JJQ/Logout';

function App(props) {
  // useAppStateStorage();

  return (
    <div className={styles.pageContainer}>
      <Router>
        <Routes>
          <Route exact path='/' element={<Base />} />
          <Route exact path='/photo' element={<PhotoRegHome />} />
          <Route exact path='/rules' element={<Rules />} />
          <Route exact path='/jjq' element={<JJQ />} />
          <Route exact path='/jjq/login' element={<Login />} />
          <Route exact path='/jjq/play' element={<Play />} />
          <Route exact path='/jjq/logout' element={<Logout />} />

          {/*
          <Route exact path='/playtrivia' element={<Home />} />
          <Route exact path='/registration' element={<Registration />} />
          <Route exact path='/done' element={<Done />} />
          <Route exact path='/trivia' element={<Trivia />} />
        */}

          <Route exact path='/leaderboard' element={<Leaderboard />} />
          <Route exact path='/displayboard' element={<RotatingLeaderboard />} />
          <Route exact path='/lookhowcooltrevoris' element={<RotatingLeaderboard />} />
          <Route exact path='/photo/registration' element={<PhotoRegistration />} />
          <Route exact path='/photo/thanks' element={<PhotoRegThanks />} />
          <Route exact path='/baqr' element={<BAQR />} />
          <Route exact path='/imt/monitor' element={<AdminUI />} />

          <Route path='*' element={<NoPage />} />
        </Routes>
      </Router>
    </div>
  );
}
// export default App;
const mapStateToProps = (state) => ({
  ...state
});
export default connect(mapStateToProps)(App);
