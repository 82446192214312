export const STORAGE_KEY_AUTH = 'CISjdi2892mJK8923jjkJj-';

export const DURATIONS = ['LAST 1 HOUR', 'LAST 12 HOURS', 'LAST 24 HOURS', 'LAST 4 DAYS', 'LAST WEEK', 'ALL TIME'];
export const PARAMS = ['1 HOUR', '12 HOUR', '24 HOUR', '4 DAY', '1 WEEK', ''];

export const API_DOMAIN_KEY = 'U0ZfSU1UX0pKUV9BUElfRE9NQUlO';
export const API_TOKEN_KEY = 'U0ZfSU1UX0pKUV9BVVRIX1RPS0VO';
export const GAME_REDIRECT_KEY = 'TUVDVU0gUkVESVJFQ1QgVVJM';

export const API_DOMAIN = process.env.REACT_APP_BASE_URL + '/api/';
export const GAME_REDIRECT = process.env.REACT_APP_BASE_URL + '/jjq/logout';
