export const Actions = {
  ERROR: "ERROR",
  TIME_CHECK: "TIME_CHECK",
  SEND_TO_HOLD: "SEND_TO_HOLD",
  REFRESH_JOURNEY_DATA: "REFRESH_JOURNEY_DATA",
  GET_EVERYONES_STATUS: "GET_EVERYONES_STATUS",
  UPDATE_USER_STATUS: "UPDATE_USER_STATUS",
  SEND_USER_NOTICE: "SEND_USER_NOTICE",
  USER_REGISTERED: "USER_REGISTERED",
  USER_DATA_UPDATED: "USER_DATA_UPDATED",
  RESET_JOURNEY: "RESET_JOURNEY",
  JOURNEY_MESSAGE: "JOURNEY_MESSAGE",
  // SUBSCRIPTIONS
  REGISTER_QA_CONTENT: "REGISTER_QA_CONTENT",
  UNREGISTER_QA_CONTENT: "UNREGISTER_QA_CONTENT",
  // Q&A
  ADD_QUESTION: "ADD_QUESTION",
  ADD_VOTE_TO_QUESTION: "ADD_VOTE_TO_QUESTION",
  REMOVE_VOTE_FROM_QUESTION: "REMOVE_VOTE_FROM_QUESTION",
  UPDATE_QUESTION: "UPDATE_QUESTION",
  ADD_ANSWER: "ADD_ANSWER",
  UPDATE_ANSWER: "UPDATE_ANSWER",
  // POLLING - VOTING
  SEND_VOTE_MESSAGE: "SEND_VOTE_MESSAGE",
};
