import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Fade } from '@mui/material';

import styles from './PhotoRegistration.module.scss';

import headerLogo from '../../assets/home/stateLogo.png';
import driveImage from '../../assets/registration/reg_drive.png';

import Menu from '../../Components/Menu/Menu';
import DialogPrompt from '../../Components/RADialog/DialogPrompt';
import NewUser from '../Registration/Forms/NewUser';
import ReturningUser from '../Registration/Forms/ReturningUser';

import reg from '../../api/register';

import { REG_TYPES, getUserError, setUserError } from '../../store/reducers/userSlice';
import TacDisplay from '../../Components/TacDisplay/TacDisplay';
import moment from 'moment';
const dtFrmt = 'HH:mm:ss';
// let regex = new RegExp("([!#-'*+/-9=?A-Z^-~-]+(\.[!#-'*+/-9=?A-Z^-~-]+)*|\"\(\[\]!#-[^-~ \t]|(\\[\t -~]))+\")@([!#-'*+/-9=?A-Z^-~-]+(\.[!#-'*+/-9=?A-Z^-~-]+)*|\[[\t -Z^-~]*])");
// let regex = new RegExp('[a-z0-9]+@[a-z]+\.[a-z]{2,3}');
// let regex = new RegExp(^[\w!#$%&'*+/=?`{|}~^-]+(?:\.[\w!#$%&'*+/=?`{|}~^-]+)*@(?:[A-Z0-9-]+\.)+[A-Z]{2,6}$);
// let regex = new RegExp(^[A-Z0-9._%+-]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$);
// let regex = new RegExp(
//     /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
// );

const PhotoRegistration = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  //   const userError = useSelector(getUserError);
  const [timeData, setTimeData] = useState({ startTime: moment().format(dtFrmt) });
  const [userError, setUserError] = useState(null);
  const [fade, setFade] = useState(true);
  const [showReg, setShowReg] = useState(false);
  const [openLink, setOpenLink] = useState(null);
  const [menuOpen, setMenuOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const regType = useSelector((state) => state.user.regType);

  const submit = (data) => {
    if (loading) return;
    try {
      // console.log("SUBMIT: ", regType, data); //inputs.firstName, inputs.lastName, inputs.email);
      setLoading(true);
      setUserError(null);
      // optIn: true, ageConf: true
      let obj =
        regType === REG_TYPES.NEW
          ? {
              ...data,
              regType,
              data: {
                regType: 'newuser',
                ageConf: data.ageConf,
                tandc: data.tandc,
                rulesAgree: data.rulesAgree,
                ...timeData,
                registeredAt: moment().format(dtFrmt)
              }
            }
          : { email: data.email, regType };

      reg
        .registerUser(obj)
        .then((res) => {
          setFade(false);
          setTimeout(() => {
            setLoading(false);
            navigate('/photo/thanks');
          }, 700);
        })
        .catch((err) => {
          console.log('[ERROR REGISTERING] ', err);
          setLoading(false);
          setShowReg(true);
          setUserError(err.message);
        });
    } catch (err) {
      console.log('[ERROR PREPARING TO REGISTER] ', err);
    }
  };

  return (
    <Fade in={fade} timeout={700}>
      <div id='main' className={styles.photoRegistration}>
        <Menu menuOpen={menuOpen} setMenuOpen={setMenuOpen} hideModes={true} resetPath='/photo' />
        <div id='container' className={styles.container}>
          <div className={styles.graphics}>
            <img className={styles.driveImage} src={driveImage} alt='driveImage' />
            <img className={styles.stateLogo} src={headerLogo} onClick={() => setMenuOpen(true)} alt='StateFarmlogo' />
          </div>
          {regType === REG_TYPES.NEW ? (
            <NewUser submit={submit} loading={loading} setOpenLink={setOpenLink} />
          ) : (
            <ReturningUser submit={submit} loading={loading} showReg={showReg} />
          )}
        </div>
        <DialogPrompt
          message={userError}
          close={() => {
            dispatch(setUserError(null));
            setLoading(false);
          }}
        />
        <TacDisplay openLink={openLink} setOpenLink={setOpenLink} />
      </div>
    </Fade>
  );
};

export default PhotoRegistration;
