
import { useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Fade } from '@mui/material';

import styles from './Leaderboard.module.scss';
import classnames from 'classnames';

import headerLogo from '../../assets/home/stateLogo.png'
import header from '../../assets/leaderboard/leaderboard-title.png';
import driveImage from "../../assets/leaderboard/LeaderboardGraphic.png";

import LeaderboardCell from '../../Components/LeaderboardCell/LeaderboardCell';
import Menu from '../../Components/Menu/Menu';

import trivia from '../../api/trivia';

import { clear as clearTrivia } from '../../store/reducers/triviaSlice';
import { clear as clearUser, getTeam, getUserToken, setUserError } from '../../store/reducers/userSlice';

function Leaderboard(props) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const token = useSelector(getUserToken);
    const team = useSelector(getTeam);

    const [fade, setFade] = useState(true);
    const [leaderboard, setLeaderboard] = useState([]);
    const [menuOpen, setMenuOpen] = useState(false);
    const [userCell, setUserCell] = useState(null);

    useEffect(() => {
        if (!token) {
            dispatch(setUserError('No user token provided. Please register before engaging in trivia.'));
            navigate('/playtrivia')
        } else {
            dispatch(trivia.getLeaderboard(token, "12 HOUR")).then(data => {
                setLeaderboard(data.leaderboard);
            });
        }
        //show leaderboard for 10 seconds, then go back to home
        var a = setTimeout(() => {
            setFade(false);
            setTimeout(() => {
                setLeaderboard([]);
                dispatch(clearTrivia());
                dispatch(clearUser());
                // navigate("/");
                window.location = "/playtrivia"
            }, 720);
        }, 12000);
        return () => clearTimeout(a);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (leaderboard?.length > 0 && team) {
            console.log('lb', leaderboard, team);
            const user = leaderboard.find((e, i) => e.teamId === team?.teamId)
            if (user && user.index >= 5) {
                setUserCell(user);
            }
        } else {
            setUserCell(null);
        }
    }, [leaderboard, team]);

    return (<Fade in={fade} timeout={700}>
        <div id="main" className={styles.leaderboard}>
            <Menu menuOpen={menuOpen} setMenuOpen={setMenuOpen} />
            <div id="container" className={styles.container}>
                <div className={styles.graphics}>
                    <img className={styles.driveImage} src={driveImage} alt="driveImage" />
                    <img className={styles.stateLogo} src={headerLogo} onClick={() => setMenuOpen(true)} alt="StateFarmlogo" />
                </div>
                <div className={styles.leaderContainer}>
                    <img src={header} className={classnames(styles.headerTitle)} alt={'Leaderboard'} />
                    <div id="list" className={styles.leaderList}>
                        {leaderboard?.filter((a, i) => i < 4).map((entry, index) => <LeaderboardCell key={entry.teamId} teamName={entry.displayName} score={entry.total} rank={entry.index} outlined={entry.teamId === team?.teamId} />)}
                        <div className={styles.leaderboardSpacer}></div>
                        {userCell && <LeaderboardCell key={userCell.teamId} teamName={userCell.displayName} score={userCell.total} rank={userCell.index} isSpecial={true} />}
                    </div>
                    {/* <div id="usercell" className={styles.leaderList}>
                    </div> */}
                </div>
            </div>
        </div>
    </Fade>);
}

const mapStateToProps = (state) => ({
    ...state
})
export default connect(mapStateToProps)(Leaderboard);