import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getUserToken } from '../../store/reducers/userSlice';
import { API_TOKEN_KEY } from '../../api/constants';

function Play() {
  const token = useSelector(getUserToken);
  console.log('Play: token', token);
  React.useEffect(() => {
    if (token) {
      localStorage.setItem(API_TOKEN_KEY, token.AccessToken);
      window.location.replace(`${process.env.PUBLIC_URL}/obv`);
      // navigate('/jjq/login');
    }
  }, []);
  return <div className='play'></div>;
}

export default Play;
