import { createSlice } from "@reduxjs/toolkit";
const init = {
  questions: [],
  error: null,
  score: 0,
  lastScore: '--',
  sessionId: null,
};

const triviaSlice = createSlice({
  name: "trivia",
  initialState: init,
  reducers: {
    setQuestions(state, action) {
      console.log("SETTING QUESTIONS: ", state, action);
      state.questions = action.payload;
    },
    setTriviaError(state, action) {
      state.error = action.payload;
    },
    setScore(state, action) {
      state.score = action.payload;
    },
    addToScore(state, action) {
      state.score += action.payload;
      state.lastScore = action.payload;
    },
    setLastScore(state, action) {
      state.lastScore = action.payload;
    },
    setSessionId(state, action) {
      state.sessionId = action.payload;
    },
    clear: () => { return init },
  },
});
export const getScore = (state) => state.trivia.score;
export const getTriviaQuestions = (state) => state.trivia.questions;
export const getTriviaError = (state) => state.trivia.error;
export const { setQuestions, setTriviaError, clear, setScore, addToScore, setLastScore, setSessionId } = triviaSlice.actions;
export default triviaSlice.reducer;
