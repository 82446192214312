import React, { useState } from 'react';
import { Fade } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import styles from './PhotoRegHome.module.scss';
import classnames from 'classnames';

import headerLogo from '../../assets/sf_red_logo.png';
import carImage1 from '../../assets/home/Car_Mode1.png';
import carImage2 from '../../assets/home/Car_Mode2.png';

import Menu from '../../Components/Menu/Menu';

import { REG_TYPES, selectAppState, setRegistrationType } from '../../store/reducers/userSlice';

function PhotoRegHome() {
  console.log('PHOTO REG HOME: RENDER');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const appState = useSelector(selectAppState);
  const [menuOpen, setMenuOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [fade, setFade] = useState(true);

  const goToReg = (e) => {
    e.preventDefault();
    if (loading) return;
    setLoading(true);
    dispatch(setRegistrationType(e.currentTarget.name));
    setFade(false);
    setTimeout(() => {
      navigate('/photo/registration');
    }, 700);
  };

  return (
    <Fade in={fade} timeout={700}>
      <div id='home' className={classnames(styles.photoHome)}>
        <Menu menuOpen={menuOpen} setMenuOpen={setMenuOpen} hideModes={true} resetPath='/photo' />
        <div id='container' className={styles.container}>
          <img className={styles.stateLogo} src={headerLogo} onClick={(e) => setMenuOpen(true)} alt='StateFarmlogo' />

          <div className={styles.homeTitle}>
            <h1 className={styles.homeTit1}>TAKE A</h1>
            <h1 className={styles.homeTit1}>PHOTO!</h1>
          </div>

          <div className={classnames(styles['ContainerMode1'])}>
            <button name={REG_TYPES.NEW} className={classnames('default-button', styles.homeButton)} onClick={goToReg}>
              <h1 className={styles.clickStart}>NEW USER</h1>
            </button>
            <button
              name={REG_TYPES.RETURNING}
              className={classnames('default-button-inverted', styles.homeButton)}
              onClick={goToReg}
            >
              <h1 className={styles.clickStart}>
                RETURNING <br />
                USER
              </h1>
            </button>
          </div>
          {/* <div className={classnames(styles[appState + "ContainerMode2"])}>

                    <button name={REG_TYPES.NEW} className={classnames('default-button', styles.homeButton)} onClick={goToReg}>
                        <h1 className={styles.clickStart}>START</h1>
                    </button>
                </div> */}

          <div className={classnames(styles.carContainer, styles[appState])}>
            <img
              className={classnames(styles.car)}
              src={appState === 'mode1' ? carImage1 : carImage2}
              alt='beigeBackground'
            />
          </div>
        </div>
      </div>
    </Fade>
  );
}

export default PhotoRegHome;
