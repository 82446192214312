/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  CircularProgress,
  Fade,
  LinearProgress,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Pagination,
  Table,
  TextField,
  Tooltip
} from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';

import styles from './BAQR.module.scss';
import classnames from 'classnames';
import './BAQR.css';

import headerLogo from '../../assets/sf_white_logo.png';
import baqr_bg from '../../assets/qr/baqr_bg.png';

import trivia from '../../api/trivia';
import { useDispatch } from 'react-redux';
import Menu from '../../Components/Menu/Menu';
import RefreshIcon from '@mui/icons-material/Refresh';
import ClearIcon from '@mui/icons-material/Clear';
import CloseIcon from '@mui/icons-material/Close';
import ListAltRoundedIcon from '@mui/icons-material/ListAltRounded';
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';

import moment from 'moment';
import { QRCodeSVG } from 'qrcode.react';
import SocketUtil from '../../socket/SocketUtil';
import Auth from '../../Components/Auth/Auth';
import { DURATIONS, PARAMS, STORAGE_KEY_AUTH } from '../../api/constants';

const LINK = 'm:';
// const LINK = 'https://mecum.statefarmactivations.com/?c1=SelectPhotoboothStart&autoemail=';
// const LINK = 'https://photos.statefarmactivations.com/register/?c1=SelectPhotoboothStart&autoemail=';
// const DURATIONS = ["LAST 1 HOUR", "LAST 12 HOURS", "LAST 24 HOURS", "LAST 4 DAYS", "LAST WEEK", "ALL TIME"];
// const PARAMS = ["1 HOUR", "12 HOUR", "24 HOUR", "4 DAY", "1 WEEK", ""];
// const STORAGE_KEY_AUTH = 'CISjdi2892mJK8923jjkJj-'
const REFRESH_INTERVAL_SEC = 30;

const socket = new SocketUtil();

function BAQR() {
  const dispatch = useDispatch();
  const [fade, setFade] = useState(true);
  const [timer, setTimer] = useState(-1);
  const [menuOpen, setMenuOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState('');
  const [users, setUsers] = useState([]);
  const [searchList, setSearchList] = useState([]);
  const [userList, setUserList] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [qrwidth, setqrwidth] = useState(128);
  const [duration, setDuration] = useState(DURATIONS[1]);
  const [lastRefresh, setLastRefresh] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const [qrEmail, setQrEmail] = useState('Select User To Begin!');
  // const [password, setPassword] = useState('');
  const qrCodeRef = useRef(null);

  const [authenticated, setAuthenticated] = useState(false);
  // const [authError, setAuthError] = useState('');

  const resizeQR = () => {
    if (qrCodeRef.current) {
      // set the width of the canvas the the div width or height, whichever fits
      var width = qrCodeRef.current.offsetWidth;
      var height = qrCodeRef.current.offsetHeight;
      var size = width > height ? 0.7 * height : 0.8 * width;
      setqrwidth(size);
    }
  };
  useEffect(() => {
    const resizeIt = () => resizeQR();
    window.addEventListener('resize', resizeIt);
    // dispatch(socket.connect('admin', () => { }, () => { }, (message) => { console.log('message', message); }));
    return () => window.removeEventListener('resize', resizeIt);
  }, []);
  useEffect(() => {
    if (selectedUser) {
      setQrEmail(LINK + selectedUser.email);
    } else {
      setQrEmail('Select User To Begin!');
    }
  }, [selectedUser]);

  useEffect(() => {
    const refreshTimer = setTimeout(() => {
      setDuration((prev) => {
        refreshList(false);
        return prev;
      });
    }, REFRESH_INTERVAL_SEC * 1000);

    return () => {
      clearTimeout(refreshTimer);
    };
  }, [lastRefresh]);

  useEffect(() => {
    var a = setInterval(() => {
      setTimer(moment().format('hh:mm:ss a'));
    }, 1000);
    return () => clearInterval(a);
  }, []);

  useEffect(() => {
    resizeQR();
  }, [authenticated, qrCodeRef]);

  const refreshList = (useLoading = true) => {
    if (!authenticated || loading) return;
    useLoading && setLoading(true);
    const tkn = window.localStorage.getItem(STORAGE_KEY_AUTH);
    dispatch(trivia.getUserList(PARAMS[DURATIONS.indexOf(duration)], tkn))
      .then((response) => {
        setUsers(response.result);
      })
      .catch((err) => {
        console.log('[ERROR REFRESHING USER LIST] ', err);
      })
      .finally(() => {
        setLoading(false);
        setLastRefresh(moment());
      });
  };
  useEffect(() => {
    if (authenticated) refreshList();
  }, [authenticated, duration]);

  useEffect(() => {
    const sl = users.filter(
      (user) =>
        !search ||
        user.firstName.toLowerCase().includes(search.toLowerCase()) ||
        user.lastName.toLowerCase().includes(search.toLowerCase()) ||
        user.email.toLowerCase().includes(search.toLowerCase())
    );
    setSearchList(sl);
  }, [users, search]);

  useEffect(() => {
    const ul = searchList.slice(page * rowsPerPage, (page + 1) * rowsPerPage);
    setUserList(ul);
  }, [searchList, rowsPerPage, page]);
  // const downloadLeaderboard = () => {
  //     dispatch(trivia.getAdminLeaderboard(PARAMS[DURATIONS.indexOf(duration)] + "&include=ry")).then((response) => {
  //         console.log('resp', response);
  //         const headers = `rank,displayName,email,total,modified\n`;
  //         const csv = headers + response.leaderboard.map((user, index) => {
  //             return `${user.index},${user.displayName},${user.email},${user.total},${user.modified}`
  //         }).join('\n');
  //         const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
  //         const url = URL.createObjectURL(blob);
  //         const link = document.createElement("a");
  //         link.setAttribute("href", url);
  //         link.setAttribute("download", `leaderboard-${moment().format('MM-DD-YYYY_HH-mm-ss')}.csv`);
  //         link.style.visibility = 'hidden';
  //         document.body.appendChild(link);
  //         link.click();
  //         document.body.removeChild(link);
  //     }).catch(err => {
  //         console.log('[ERROR DOWNLOADING LEADERBOARD] ', err);
  //     });
  // }
  // const downloadUserList = () => {
  //     dispatch(trivia.getUserList(PARAMS[DURATIONS.indexOf(duration)] + "&include=ry")).then((response) => {
  //         const headers = `id,name,email,created\n`;
  //         const csv = headers + response.result.map((user, index) => {
  //             return `${index + 1},${user.name},${user.email},${user.created}`
  //         }).join('\n');
  //         const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
  //         const url = URL.createObjectURL(blob);
  //         const link = document.createElement("a");
  //         link.setAttribute("href", url);
  //         link.setAttribute("download", `userlist-${moment().format('MM-DD-YYYY_HH-mm-ss')}.csv`);
  //         link.style.visibility = 'hidden';
  //         document.body.appendChild(link);
  //         link.click();
  //         document.body.removeChild(link);
  //     }).catch(err => {
  //         console.log('[ERROR DOWNLOADING USER LIST] ', err);
  //     });
  // }

  // const authenticate = () => {
  //     // setAuthError("Unable to do some things because of a longish m asf asd asd asd fasd fasessage")
  //     setAuthError('');
  //     dispatch(trivia.authBA({ code: password })).then((response) => {
  //         setAuthenticated(true);
  //         window.localStorage.setItem(STORAGE_KEY_AUTH, response.token);
  //     }).catch(err => {
  //         console.log('[ERROR AUTHENTICATING] ', err);
  //         setAuthError(err.message)
  //     });
  // }
  // useEffect(() => {
  //     const tkn = window.localStorage.getItem(STORAGE_KEY_AUTH);
  //     if (tkn) {
  //         dispatch(trivia.checkBA(tkn)).then((response) => {
  //             setAuthenticated(true);
  //         }).catch(err => {
  //             console.log('[ERROR AUTHENTICATING BA CHECK] ', err);

  //             setAuthError(err.message)
  //         });
  //     }
  // }, []);

  return (
    <Fade in={fade} timeout={700}>
      <div className={styles.qrContainer}>
        <Menu menuOpen={menuOpen} setMenuOpen={setMenuOpen} hideModes={true} />
        <img className={styles.baqrBackground} src={baqr_bg} alt='BA QR' />
        {
          authenticated ? (
            <>
              <div className={styles.timer}>{timer}</div>
              <img
                className={styles.stateLogo}
                src={headerLogo}
                onClick={(e) => setMenuOpen(true)}
                alt='StateFarmlogo'
              />
              <div className={styles.worker}>
                <div className={styles.userListContainer}>
                  <div className={styles.userList}>
                    <input
                      className={styles.userSearch}
                      disabled={loading}
                      type='text'
                      placeholder='Search for a user'
                      value={search}
                      onChange={(e) => {
                        setSearch(e.currentTarget.value);
                        setPage(0);
                      }}
                    />
                    <div className={styles.clearSearch}>
                      <Tooltip title='Clear Search Field' placement='top'>
                        <CloseIcon fontSize='small' onClick={(e) => setSearch('')} />
                      </Tooltip>
                    </div>
                    <List className={styles.userListItems}>
                      {loading ? (
                        <ListItem className={styles.userListItem}>
                          <LinearProgress className={styles.progress} />
                        </ListItem>
                      ) : (
                        userList.map((user, index) => {
                          return (
                            <ListItem
                              className={classnames(styles.userListItem, {
                                [styles.selectedItem]: qrEmail.includes(user.email)
                              })}
                              key={index}
                              disablePadding
                            >
                              <ListItemButton className={styles.itemButton} onClick={() => setSelectedUser(user)}>
                                {/* <ListItemButton className={styles.itemButton} onClick={() => setQrEmail(LINK + user.email)}> */}
                                <ListItemText
                                  className={styles.itemName}
                                  primary={user.firstName + ' ' + user.lastName}
                                />
                                <ListItemText className={styles.itemEmail} primary={user.email} />
                                <ListItemText
                                  className={styles.itemCreated}
                                  primary={moment(user.created).local().format('MM-DD  hh:mm a')}
                                />
                              </ListItemButton>
                            </ListItem>
                          );
                        })
                      )}
                    </List>
                    <div className={styles.pagingFooter}>
                      {/* add a rowsPerPage choice box with 10, 20, 50, and 100 as options */}
                      <div className={styles.rowSelect}>
                        <div className={styles.pagingFooterText}>Rows/page:</div>
                        <select
                          className={styles.pagingFooterSelect}
                          value={rowsPerPage}
                          onChange={(e) => setRowsPerPage(e.currentTarget.value)}
                        >
                          <option value={10}>10</option>
                          <option value={20}>20</option>
                          <option value={50}>50</option>
                          <option value={100}>100</option>
                        </select>
                        <div className={styles.pagingFooterText}>[{users.length}]</div>
                        {/* <div className={styles.pagingFooterText}>Total: {searchList.length}/{users.length}</div> */}
                      </div>
                      <div className={styles.controlPanel}>
                        {lastRefresh && (
                          <Tooltip title='Time to next refresh' placement='top'>
                            {/* ONLY works due to 1 second timer (top left corner) triggering render every second */}
                            <CircularProgress
                              color='inherit'
                              className={styles.progress}
                              variant='determinate'
                              value={
                                (100 * (REFRESH_INTERVAL_SEC - (moment().second() - lastRefresh.second()))) /
                                REFRESH_INTERVAL_SEC
                              }
                            />
                          </Tooltip>
                        )}
                        <Tooltip title='Select List Time period' placement='top'>
                          <select value={duration} onChange={(e) => setDuration(e.currentTarget.value)}>
                            {DURATIONS.map((d, i) => {
                              return (
                                <option key={i} value={d}>
                                  {d}
                                </option>
                              );
                            })}
                          </select>
                        </Tooltip>
                        <Tooltip title='Refresh List' placement='top'>
                          <RefreshIcon fontSize='small' onClick={refreshList} />
                        </Tooltip>
                        <Tooltip title='Clear Selection' placement='top'>
                          <ClearIcon fontSize='small' onClick={() => setSelectedUser(null)} />
                        </Tooltip>
                        {/* <Tooltip title={`Download Leaderboard For ${duration}`} placement="top">
                                            <ListAltRoundedIcon fontSize="small" onClick={() => downloadLeaderboard()} />
                                        </Tooltip>
                                        <Tooltip title={`Download User List For ${duration}`} placement="top">
                                            <PeopleOutlineIcon fontSize="small" onClick={() => downloadUserList()} />
                                        </Tooltip> */}
                      </div>
                      <Pagination
                        size='small'
                        className={styles.pagingObject}
                        count={Math.ceil(searchList.length / rowsPerPage)}
                        page={page + 1}
                        onChange={(e, p) => setPage(p - 1)}
                      />
                    </div>
                    <div className={styles.listDisclaimer}>Only seeing users who registered within {duration}</div>
                  </div>
                  <div className={styles.qrOutput} ref={qrCodeRef}>
                    <QRCodeSVG
                      value={qrEmail}
                      size={qrwidth}
                      level='Q'
                      bgColor='#FFFFFF'
                      fgColor='#000000'
                      opacity={selectedUser ? 1.0 : 0.0}
                    />
                    <div className={styles.userName}>{selectedUser?.name}</div>
                    <div className={styles.textPreview}>
                      <a href={qrEmail} target='__blank' rel='noreferrer noopener'>
                        {qrEmail}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <Auth authenticated={authenticated} setAuthenticated={setAuthenticated} />
          )
          // <div className={styles.authenticationForm}>
          // <div className={styles.authenticationFormBody}>
          //     <div className={styles.authenticationFormHeader}>
          //         Please authenticate to continue
          //     </div>
          //     <TextField className={styles.authenticationFormInput} label="Password" variant="outlined" type="password" value={password} onChange={(e) => setPassword(e.currentTarget.value)} onKeyDown={(e) => e.key === 'Enter' && authenticate(e)} />
          //     {authError && <div className={styles.authenticationFormError}>{authError}</div>}
          //     <Button className={styles.authenticationFormButton} variant="contained" color="primary" onClick={authenticate}>Authenticate</Button>
          // </div>
          // </div>
        }
      </div>
    </Fade>
  );
}

export default BAQR;
