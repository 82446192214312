import React from 'react';
import classNames from 'classnames';
import './TacDisplay.module.css';
import styles from './TacDisplay.module.scss';

function Rules() {
  return (
    <div className={classNames(styles.tacObject, styles.rules)}>
      {/* <div style={{ width: '100%', height: '100%' }}> */}
      {/* <iframe
                src="/rules/TC23_Official_Rules.pdf"
                title="Official Rules"
                className="rules"
                // style={{ height: '100%', width: '100%' }}
            /> */}

      <h1>OFFICIAL CONTEST RULES</h1>
      <p>
        The State Farm® Arcade Challenge ("Contest") will run during TwitchCon Las Vegas from October 20, 2023, to
        October 22, 2023. NO PURCHASE OR PAYMENT OF ANY KIND IS NECESSARY TO ENTER OR WIN. Void where prohibited or
        restricted by law. Open to registered attendees of TwitchCon who are legal residents of the United States who
        are of majority age in their state of residence as of the start of the Contest.
      </p>

      <p>
        NO PURCHASE OR PAYMENT OF ANY KIND IS NECESSARY TO ENTER OR WIN. A PURCHASE DOES NOT INCREASE YOUR CHANCES OF
        WINNING A PRIZE. THIS CONTEST IS ONLY OPEN TO LEGAL RESIDENTS OF THE UNITED STATES. DO NOT PARTICIPATE IF YOU
        ARE NOT A TWITCHCON LAS VEGAS REGISTERED ATTENDEE AND DO NOT RESIDE IN THE UNITED STATES OR THE DISTRICT OF
        COLUMBIA AT THE TIME OF ENTRY AND ARE NOT OF MAJORITY AGE. VOID WHERE PROHIBITED OR RESTRICTED BY LAW. SUBJECT
        TO ALL APPLICABLE FEDERAL STATE AND LOCAL LAWS AND REGULATIONS.
      </p>

      <p>
        ARBITRATION NOTICE: TO THE EXTENT PERMITTED BY LAW AND EXCLUDING ANY PARTICIPANTS RESIDING OR WORKING WITHIN THE
        STATES OF CALIFORNIA NEW JERSEY OR NEW YORK YOU AGREE THAT BY ENTERING THE CONTEST YOU AGREE THAT DISPUTES
        BETWEEN YOU AND ANY CONTEST ENTITY (AS DEFINED HEREIN) WILL BE RESOLVED BY BINDING INDIVIDUAL ARBITRATION AND
        YOU WAIVE YOUR RIGHT TO PARTICIPATE IN A JURY TRIAL CLASS ACTION LAWSUIT OR CLASS-WIDE ARBITRATION. SEE
        DISPUTES/ARBITRATION PROVISION CONTAINED HEREIN.
      </p>

      <h2>1. GENERAL</h2>
      <p>
        The State Farm® Arcade Challenge ("Contest") is administered and sponsored by Infinity Marketing Team LLC
        ("Sponsor"). The Contest is subject to these Official Rules and to all applicable federal, state, and local
        laws.
      </p>
      <h2>2. PROMOTION PERIOD</h2>
      <p>
        The Contest will be held at TwitchCon Las Vegas from October 20, 2023, to October 22, 2023. Each “Promotion
        Period” will run from 8:00 am to 6:00 pm on October 20, 2023; October 21, 2023; and October 22, 2023. Eligible
        participants (“participant”, "you" or "your") must register for the Contest in person at TwitchCon Las Vegas
        during any Promotion Period. Sponsor's computer is the official time-keeping device for the Contest.
      </p>

      <h2>3. ELIGIBILITY</h2>
      <p>
        The Contest is open only to registered attendees of TwitchCon Las Vegas who are legal residents of the fifty
        (50) United States or the District of Columbia and who as of the start of the applicable Promotion Period are
        the age of majority or older in their state of residence (19+ in AL, DE, and NE; 21+ in MS, and 18+ in all other
        states).
      </p>

      <p>
        Do not enter if you are not a registered attendee of TwitchCon Las Vegas, are not located in the United States
        or the District of Columbia, and are not the age of majority at the time of entry. Officers, directors,
        employees, agents, and representatives of Sponsor, State Farm, and each of their respective parent,
        subsidiaries, affiliated and related companies or agencies, and/or any person or company working directly on the
        Contest (collectively, the “Contest Entities”), together with the immediate family members and/or those living
        in the same household of such persons (collectively, “Household Members”), are not eligible to participate in
        the Contest or win a prize. For purposes of the Contest, “Household Members” shall mean those people who share
        the same residence for at least three months a year, whether or not related; “Immediate Family Members” shall
        mean parents, step-parents, legal guardians, children, step-children, siblings, step-siblings, or spouses,
        regardless of where they live. Void where prohibited or restricted by law. All federal, state, and local laws
        and regulations apply. You are solely responsible for all costs or expenses associated with participation in the
        Contest.
      </p>
      <h2>4. HOW TO ENTER</h2>
      <p>
        During each applicable Promotion Period, you may enter the Contest by visiting The State Farm® Arcade Challenge
        Booth at TwitchCon Las Vegas and completing the registration at one of the designated kiosks which is where the
        Contest gameplay will take place.
      </p>
      <h2>5. DETERMINATION OF WINNER</h2>
      <p>
        During the Promotion Period, each registered participant will play a video game on an arcade cabinet. Each game
        will consist of two (2) rounds of 90-second gameplay. At the end of each round, the registered participant will
        receive a score based on a combination of: (i) the total distance travelled, (ii) the total number of points
        received from the point items collected, and (iii) the number of times the participant was struck by an enemy.
        Sponsor’s computer is the official time-keeping and scoring device for the Contest. Any registered participant
        that fails to move forward or collect any point items during the round will receive a score of zero (0) for that
        round. At the end of each game, the registered participant’s scores from both rounds will be logged into
        Sponsor’s computer. The higher of the two scores will be considered for inclusion on the Daily Leaderboard. At
        the end of each Promotion Period, the three (3) highest scores will be placed on the Daily Leaderboard. The
        registered participants with the top three scores on the Daily Leaderboard will receive a prize as described
        below in Paragraph 6. While multiple plays by a single registered participant are permitted, each registered
        participant is only eligible for one (1) prize. In the event that a single registered participant receives more
        than one (1) placement on the Daily Leaderboard, that participant’s highest score will be recorded and the
        remaining scores will be disqualified. While Daily Leaderboard winners are permitted to play during future
        Promotion Periods, their scores will be ineligible to receive additional prizes. In the event that two or more
        registered participants tie for the first (1st), second (2nd), or third (3rd) placement on the Daily
        Leaderboard, the participant’s second highest score from any round played during the Promotion Period will be
        considered. The participant with the second highest score will receive placement on the Leaderboard. In the
        event that the second highest scores from both participants are identical, there will be no tie-breaker and both
        tied participants will receive placement on the Leaderboard and both tied participants will receive a prize. The
        winners will be notified via email after the Promotion Period has ended. The email will be sent to the email
        address utilized by the participant during registration at The State Farm® Arcade Challenge Booth kiosk at
        TwitchCon Las Vegas. All game play becomes the property of Sponsor. Rules are subject to change at Sponsor’s
        discretion. Use of bots, computer scripts or programs and other automatic methods of game play are prohibited
        and will void your eligibility to win a prize. Registered participants who use inappropriate or offensive
        ‘Usernames’ will be disqualified from Leaderboard placement and will be ineligible to receive a prize. Sponsor
        will determine in its sole discretion whether a ‘Username’ is inappropriate or offensive. Sponsor will determine
        in its sole discretion all eligibility requirements.
      </p>
      <h2>6. PRIZES</h2>
      <p>Each eligible participant who receives placement on the Leaderboard at the end of a Promotion Period...</p>
      <ul>
        <li>
          <strong>1st Place Winner</strong> The registered participant who receives the highest score during a Promotion
          Period (the “1st Place Winner”) will receive one (1) electronic gift card in the amount of $200 to one (1) of
          the following retailers of their choosing: Steam, Xbox, Play Station, or Nintendo. The gift card will be sent
          via email to the email address utilized by the participant during registration at The State Farm® Arcade
          Challenge Booth kiosk at TwitchCon Las Vegas.
        </li>
        <li>
          <strong>2nd Place Winner</strong> The registered participant who receives the second highest score during a
          Promotion Period (the “2nd Place Winner”) will receive one (1) electronic gift card in the amount of $150 to
          one (1) of the following retailers of their choosing: Steam, Xbox, Play Station, or Nintendo. The gift card
          will be sent via email to the email address utilized by the participant during registration at The State Farm®
          Arcade Challenge Booth kiosk at TwitchCon Las Vegas.
        </li>{' '}
        <li>
          <strong>3rd Place Winner</strong> The registered participant who receives the third highest score during a
          Promotion Period (the “3rd Place Winner”) will receive one (1) electronic gift card in the amount of $100 to
          one (1) of the following retailers of their choosing: Steam, Xbox, Play Station, or Nintendo. The gift card
          will be sent via email to the email address utilized by the participant during registration at The State Farm®
          Arcade Challenge Booth kiosk at TwitchCon Las Vegas
        </li>
      </ul>

      <h2>7. TAXES</h2>
      <p>
        Winners are solely responsible for any and all federal, state, municipal, and local taxes, fees, and other
        government assessments associated with receipt and use of a prize or participation in this Contest. Federal and
        state withholding laws are subject to change without notice.
      </p>
      <h2>8. GENERAL CONDITIONS</h2>
      <p>
        <ul>
          <li>
            <strong>a.</strong>Sponsor reserves the right in its discretion to disqualify any participant who: (i)
            tampers or attempts to tamper with the operation of the Contest; (ii) violates the Official Rules; or (iii)
            acts in an unsportsmanlike or disruptive manner, or with intent to annoy, abuse, threaten or harass any
            other person; (iv) uses a disrespectful, inappropriate, foul language username with malicious intent to
            disrupt the Leaderboard. In the event a participant attempts to deliberately tamper with the Contest or
            damage any associated equipment, website or platform, or undermine the operation of the Contest, or
            otherwise violate any applicable laws in connection with such participant’s participation in the Contest,
            Sponsor reserves the right to pursue all legal remedies available to it against such participant.
          </li>
          <li>
            <strong>b.</strong> Contest Entities are not responsible for any incorrect or inaccurate information,
            whether caused by any Round participants or users, human error, tampering, hacking or by any of the
            equipment or programming associated with or utilized in the Contest, and further assume no responsibility
            for any error, omission, interruption, deletion, defect, delay in operation or transmission (including
            availability or accessibility of the internet), incompatibility, communications failure, theft, loss or
            destruction of entries, nor for the failure to capture an entry or other information. Contest Entities are
            not responsible for injury or damage to participants’ or to any other person’s phone, tablet, computer or
            other device related to or resulting from downloading materials or otherwise as a result of your
            participation in the Contest. If, for any reason, the Contest or any element thereof is not capable of
            running as planned by reason of, but not limited to, tampering, unauthorized intervention, fraud, force
            majeure, technical or other failures or errors, or any other causes similar or dissimilar which Sponsor
            deems, in its opinion, could corrupt or affect the administration, security, fairness, integrity or proper
            conduct of this Contest or any element thereof, Contest Entities reserve the right at their discretion to
            cancel, terminate, modify or suspend the Contest or any element thereof and select Winners from non-suspect,
            eligible entries received prior to the action or as otherwise may be deemed fair and equitable by the
            Contest Entities. In the event of any discrepancy, ambiguity, inconsistency, printing or any other error or
            miscommunication in any communications, announcements, advertising or promotional materials relating to this
            Contest, these Official Rules shall govern. Contest Entities shall have no responsibility or obligation to a
            potential Winner who is ineligible for the prize, or is unable to or who does not accept the prize, for any
            reason.
          </li>
          <li>
            <strong>c.</strong> Prizes are awarded “as is” without any express or implied warranty or guarantee from
            Contest Entities, including but not limited to, the implied warranties of merchantability, fitness for a
            particular purpose and non-infringement. Only the prizes as described herein are available to be awarded,
            and in no event will the Contest Entities be responsible for awarding more or different prizes than stated
            herein. Prizes cannot be assigned, transferred, or substituted, except by Sponsor who may, at its
            discretion, substitute a prize or any part thereof with a prize of equal or greater value. Prizes are not
            redeemable for cash.
          </li>
        </ul>
      </p>
      {/* <!-- Additional subpoints and details of the section continue here --> */}

      <h2>9. PRIVACY</h2>
      <p>
        Information submitted to Sponsor in connection with the Contest will be treated in accordance with these
        Official Rules and Sponsor’s Privacy Policy, which may be amended from time to time.
      </p>
      <h2>10. INDEMNIFICATION AND RELEASE OF LIABILITY</h2>
      <p>
        BY PARTICIPATING IN THE CONTEST YOU AGREE TO THE FULLEST EXTENT PERMITTED BY LAW TO RELEASE, DISCHARGE AND HOLD
        HARMLESS TWITCH, THE CONTEST ENTITIES AND THEIR RESPECTIVE PARENT, AFFILIATES AND SUBSIDIARY COMPANIES,
        ADVERTISING AND PROMOTION AGENCIES, AND THEIR RESPECTIVE OFFICERS, MANAGERS, DIRECTORS, EMPLOYEES, INDEPENDENT
        CONTRACTORS, REPRESENTATIVES AND AGENTS (“RELEASED PARTIES”) FROM AND AGAINST ANY AND ALL ALLEGED OR ACTUAL
        CLAIMS, CAUSES OF ACTION, DEMANDS, LOSSES, SETTLEMENTS, LIABILITIES, AND DAMAGES OF ANY KIND WHATSOEVER EXISTING
        NOW OR ARISING IN THE FUTURE (INCLUDING, WITHOUT LIMITATION, BODILY INJURY, PERSONAL INJURY, DEATH, DISABILITY
        AND PROPERTY DAMAGE, VIOLATION OF PROPRIETARY, PUBLICITY, PRIVACY OR ANY OTHER RIGHT), COSTS AND EXPENSES
        (INCLUDING, WITHOUT LIMITATION, REASONABLE ATTORNEYS’ FEES, COURT COSTS, SETTLEMENT AND DISBURSEMENTS) DIRECTLY
        OR INDIRECTLY ARISING OUT OF ANY USE OF YOUR ENTRY INTO AND PARTICIPATION IN THE CONTEST IN WHOLE OR IN PART;
        THE ACCEPTANCE, POSSESSION, USE OR MISUSE OF A PRIZE; PARTICIPATION IN ANY CONTEST OR PRIZE RELATED ACTIVITY; OR
        OTHER PARTICIPATION IN THIS CONTEST. By entering the Contest or accepting a prize, you covenant to the fullest
        extent permitted by law not to sue any Released Party or cause them to be sued regarding any matter released
        above, and not to disaffirm, limit or rescind this release. A waiver by one or more of the Contest Entities of
        any term in these Official Rules does not constitute a waiver of any other provision. SOME JURISDICTIONS DO NOT
        ALLOW CERTAIN LIMITATIONS OR EXCLUSION OF LIABILITY, SO THE FOREGOING MAY NOT APPLY TO EVERY PARTICIPANT. Any
        provision adjudged to be invalid shall be struck from these Official Rules and the remainder shall continue in
        full force and effect.
      </p>

      <h2>11. FORCE MAJEURE</h2>
      <p>
        Contest Entities shall not be liable to participants, a potential or verified Winner or any other person or
        entity for failure to execute the Contest, or supply a prize or any part thereof, by reason of any act of God,
        any action, regulation, order or request by any governmental or quasi-governmental entity (whether or not the
        action, regulation, order or request proves to be invalid), equipment failure, terrorist act, cyber-attack,
        earthquake, war, fire, flood, explosion, unusually severe weather, hurricane, epidemics, pandemics, embargo,
        labor dispute or strike (whether legal or illegal), labor or material shortage, transportation interruption of
        any kind, work slow-down, civil disturbance, insurrection, riot, cancellation or delay of any event, or any
        similar or dissimilar event beyond their reasonable control.
      </p>

      <h2>12. DISCLAIMERS</h2>
      <p>
        Contest Entities are not responsible for lost, late, or misdirected entries, or telecommunication, internet, or
        hardware or software failures, including by reason of any bug or computer virus or other failure. Contest
        Entities may cancel, modify, or terminate the Contest (including winner selection and prizes) without prior
        notice if it is not capable of completion as planned, including by reason of infection of computer virus, tamp
      </p>
      <h2>13. ALTERNATIVE DISPUTE RESOLUTION; ARBITRATION</h2>
      <p>
        To the extent permitted by law and excluding participants residing or working within the States of California,
        New Jersey or New York, any disputes relating to the terms of these Official Rules or the breach thereof, or
        connected with the Contest or the prizes awarded, shall be submitted to binding arbitration in Los Angeles, CA
        in accordance with the Commercial Arbitration rules of the American Arbitration Association (the “AAA”). Each
        party agrees that any dispute resolution proceedings will be conducted only on an individual basis and not in a
        class, consolidated or representative action. Each party hereby waives the right to participate in any class
        action, whether in court or arbitration. In the event that either party desires to arbitrate any such dispute,
        such party shall so notify the other party and the parties shall endeavor in good faith, for a period of 30
        days, to resolve such dispute without arbitration. In the event that the parties cannot resolve the dispute
        within such 30 day period, then within 10 days thereafter, the parties shall jointly designate a single
        arbitrator to hear the dispute, or, if the parties are unable to jointly select an arbitrator, an arbitrator
        shall be chosen according to the rules of the AAA. The arbitration, including the rendering of the award, shall
        take place in Los Angeles, CA and shall be the exclusive forum for resolving such dispute, controversy, or
        claim. The decision of the arbitrator shall be final and binding upon the parties hereto. The decision of the
        arbitrator shall be executory, and judgment thereon may be entered by any court of competent jurisdiction. If
        for any reason a claim proceeds in court rather than in arbitration, each party waives any right to a jury
        trial, excluding in any civil matters brought within the States of California, New Jersey, or New York.
      </p>
      <h2>14. SEVERABILITY</h2>
      <p>
        The invalidity, illegality, or unenforceability of any provision of these Official Rules shall in no way affect
        the validity, legality, or enforceability of any other provision hereof. Any invalid, illegal, or unenforceable
        provision shall be deemed severed from these Official Rules and the balance of these Official Rules shall be
        construed and enforced as if these Official Rules did not contain such invalid, illegal, or unenforceable
        provision.
      </p>
      <h2>15. WINNERS’ LIST</h2>
      <p>
        To obtain a list of Winners, send an email to info@infinitymarketing.com within thirty (30) days of the end of
        the applicable Promotion Period. The email should contain “State Farm Arcade Challenge” in the subject field.
        Or, send a stamped, self-addressed envelope to: “State Farm Arcade Challenge” c/o Infinity Marketing Team, LLC,
        8575 Higuera Street, Culver City, CA 90232. VT residents may omit return postage.
      </p>
      <h2>16. SPONSOR NAME AND ADDRESS</h2>
      <p>Infinity Marketing Team LLC, 8575 Higuera Street, Culver City, CA 90232.</p>

      <h2>17. PRIZE PROVIDER NAME AND ADDRESS</h2>
      <p>
        All prizes as provided in Paragraph 6 are provided by: Infinity Marketing Team LLC, 8575 Higuera Street, Culver
        City, CA 90232.
      </p>
      <br />
      <br />
      <br />
    </div>
  );
}

export default Rules;
