import { Actions } from "./Actions.js";
import moment from "moment";

class ActionHandler {
  static handleMessage = (message) => {
    return (dispatch) => {
      const target = JSON.parse(message);
      // console.log(target);
      const action = target.action;
      if (action && action.length > 0) {
        console.log("[RECEIVED ACTION]:", action, target);
        if (action === Actions.TIME_CHECK) {
          console.log("TIME_CHECK", target.data);
        } else if (action === Actions.UPDATE_USER_STATUS) {
          dispatch({ type: "lounge/UPDATE_USER", payload: target.data });
        } else if (action === Actions.SEND_USER_NOTICE) {
          dispatch({ type: "messages/ADD_MESSAGE", payload: target.data });
        } else if (action === Actions.ADD_QUESTION) {
          console.log("adding in handler: ", message);
          dispatch({ type: "qa/ADD_QUESTION", payload: target.data, contentId: target.data.contentId });
        } else if (action === Actions.UPDATE_QUESTION) {
          dispatch({ type: "qa/UPDATE_QUESTION", payload: target.data, contentId: target.data.contentId });
        } else if (action === Actions.UPDATE_ANSWER) {
          dispatch({
            type: "qa/UPDATE_ANSWER",
            payload: target.data,
            contentId: target.data.contentId,
            questionId: target.data.questionId,
          });
        } else if (action === Actions.ADD_ANSWER) {
          dispatch({
            type: "qa/ADD_ANSWER",
            payload: target.data,
            contentId: target.data.contentId,
            questionId: target.data.questionId,
          });
        } else if (action === Actions.ADD_VOTE_TO_QUESTION) {
          dispatch({
            type: "qa/ADD_VOTE_TO_QUESTION",
            payload: target.data,
            contentId: target.data.contentId,
            questionId: target.data.questionId,
          });
        } else if (action === Actions.REMOVE_VOTE_FROM_QUESTION) {
          dispatch({
            type: "qa/SUBTRACT_VOTE_FROM_QUESTION",
            payload: target.data,
            contentId: target.data.contentId,
            questionId: target.data.questionId,
          });
        } else if (action === Actions.SEND_VOTE_MESSAGE) {
          console.log("action", action, target);
          if (target.data.state) {
            //OPEN, CLOSE, RESULTS, WAITING
            dispatch({ type: "viewer/SET_VOTE_STATE", payload: target.data.state });
            if (target.data.state === "OPEN") {
              console.log("open");
              dispatch({ type: "sound/PLAY_INDEXED_NOTICE", payload: 2 });
            }
          }
          if (target.data.vote) {
            dispatch({ type: "viewer/SET_VOTE_DATA", payload: target.data.vote });
          }
          if (target.data.result) {
            dispatch({ type: "viewer/SET_VOTE_RESULTS", payload: target.data.result });
          }
        } else if (action === Actions.USER_REGISTERED) {
          dispatch({ type: "lounge/ADD_USER", payload: target.data });
        } else if (action === Actions.USER_DATA_UPDATED) {
          dispatch({ type: "lounge/UPDATE_USER", payload: target.data });
        } else if (action === Actions.RESET_JOURNEY) {
          dispatch({ type: "journey/SET_JOURNEY", payload: null });
          dispatch({ type: "viewer/DESTROY", payload: null });
        } else if (action === Actions.JOURNEY_MESSAGE) {
          dispatch({ type: "journey/SET_JOURNEY_MESSAGE", payload: target });
        } else if (action === "SET_SESSION_VALUE") {
          console.log("target.", target.data);
          dispatch({ type: "journey/SET_SESSION", payload: target.data });
        } else if (action === "SET_START_DATE") {
          dispatch({ type: "journey/SET_START_DATE", payload: moment.utc(target.data) });
        } else if (action === "SET_DAYTAG_VALUE") {
          dispatch({ type: "journey/SET_DAYTAG", payload: target.data });
        } else if (action === "REFRESH") {
          if (window.location.pathname === "/journey")
            setTimeout(() => window.location.reload(false), 2000 + Math.random() * 10000);
        } else if (action === "GO_TO_PAGE") {
          window.location = target.data;
        } else if (action === "REFRESH_ALL") {
          setTimeout(() => window.location.reload(false), 2000 + Math.random() * 20000);
        } else if (action === "SEND_USER_MESSAGE") {
          console.log("message: ", target);
          dispatch({
            type: "messages/ADD_MESSAGE",
            payload: {
              message: target.data.message,
              link_to: target.data.link_to,
              from_sub: "system",
              local: true,
              created: moment.utc().format(),
              messageId: moment.utc().unix(),
            },
            // payload: {
            //   message: "PM Session is open",
            //   link_to: "/journey",
            //   from_sub: "system",
            //   local: true,
            //   created: moment.utc().format(),
            //   messageId: moment.utc().unix(),
            // },
          });
          dispatch({ type: "sound/PLAY_MESSAGE_NOTICE", payload: true });
        }
      }
    };
  };
}

export default ActionHandler;
