// import { Auth } from "aws-amplify";
import ActionHandler from "./ActionHandler.js";
import moment from "moment";
import { setConnected } from "../store/reducers/adminSlice.js";

class SocketUtil {
  static enabled = process.env.REACT_APP_USE_SOCKET === "true";
  static URL = process.env.REACT_APP_SOCKET_URL;
  // process.env.REACT_APP_SOCKET_URL === "false"
  //   ? window.location.hostname.includes("localhost")
  //     ? "ws://localhost:3030/link"
  //     : "ws://" + window.location.hostname + "/link"
  //   : process.env.REACT_APP_SOCKET_URL;

  constructor() {
    this.ws = null;
    this.connecting = false;
    this.lastIncoming = 0;
    this.lastOutgoing = 0;
    this.instance = this;
    this.restart = true;
    if (SocketUtil.enabled)
      setInterval(() => {
        this.checkConnection();
      }, 60 * 1000);
  }

  checkConnection = () => {
    if (this.ws && SocketUtil.enabled) {
      // console.log('checking connection');
      //Need to determine if we need to reconnect or if the user has been idle
    }
  };

  sendMessage = (message) => {
    if (this.ws) {
      try {
        if (typeof message === "string") this.ws.send(message);
        else this.ws.send(JSON.stringify(message));
        this.lastOutgoing = moment();
      } catch (err) {
        console.log("[SOCKET MESSAGE SEND ERROR]", err);
      }
    }
  };

  connect = (type, onOpenCallback, onCloseCallback, messageHandler) => {
    var p = this;
    return (dispatch) => {
      if (!SocketUtil.enabled) return;
      this.connecting = true;
      this.disconnect();
      // Auth.currentSession()
      //   .then((session) => {
      // console.log("[SOCKET] ", SocketUtil.URL + '?pcid="+type');
      this.ws = new WebSocket(SocketUtil.URL + `?pcid=${type}`);
      this.ws.onopen = function open() {
        console.log("[SOCKET CONNECTED]");
        p.lastIncoming = p.lastOutgoing = moment();

        dispatch(setConnected(true));
        this.connecting = false;
        if (onOpenCallback) onOpenCallback();
      };
      this.restart = true;
      this.ws.onclose = function close() {
        console.log("[SOCKET DISCONNECTED]");
        p.ws = null;
        p.lastIncoming = 0;
        p.lastOutgoing = 0;
        var reconnectTimeout = 10 * 1000;
        dispatch(setConnected(false));
        if (p.restart) {
          console.log("retrying in " + reconnectTimeout + "ms");
          setTimeout(() => {
            dispatch(p.connect(type, onOpenCallback, onCloseCallback, messageHandler));
          }, reconnectTimeout);
        } else {
          this.restart = true;
        }
        if (onCloseCallback) onCloseCallback();
      };
      this.ws.onmessage = function incoming(message) {
        this.lastIncoming = moment();
        if (messageHandler && messageHandler.handleMessage) {
          messageHandler.handleMessage(JSON.parse(message.data));
        } else
          ActionHandler.handleMessage(message.data);
      };
      this.ws.onerror = function error(er) {
        console.log("[WEB SOCKET ERROR]", er);
      };
      // })
      // .catch((err) => {
      //   console.log("[AUTO ERROR FOR SOCKET INIT]", err);
      // });
    };
  };

  disconnect = () => {
    if (this.ws) {
      try {
        this.restart = false;
        this.ws.close();
        this.ws = null;
      } catch (error) {
        console.log("[SOCKET CLOSE ERROR]", error);
      }
    }
  };

  isConnected = () => {
    return (this.ws && this.ws.readyState === WebSocket.OPEN) || false;
  };

  isConnecting = () => {
    return (this.ws && this.ws.readyState === WebSocket.CONNECTING && !this.connecting) || false;
  };
}

export default SocketUtil;
