import React from 'react';

import TR from '../../assets/jjq/form/corner_TR.png';
import TL from '../../assets/jjq/form/corner_TL.png';
import BR from '../../assets/jjq/form/corner_BR.png';
import BL from '../../assets/jjq/form/corner_BL.png';
import ST from '../../assets/jjq/form/side_T.png';
import SB from '../../assets/jjq/form/side_B.png';
import SR from '../../assets/jjq/form/side_R.png';
import SL from '../../assets/jjq/form/side_L.png';

import './BGForm.css';
import { Note1, Note2, Tire } from '../Notes';

function BGForm({ reg }) {
  return (
    <div className={`form-container ${reg ? 'taller' : ''}`}>
      <div className='form-row form-t-r'>
        <div className='corner corner-tl'>
          <img className='corner-image' src={TL} alt='corner' />
        </div>
        <div className='side side-t'>{/* <img className="side-image side-t-i" src={ST} alt="side" /> */}</div>
        <div className='corner corner-tr'>
          <img className='corner-image' src={TR} alt='corner' />
        </div>
      </div>
      <div className='form-row form-m-r'>
        <div className='side side-l'>{/* <img className="side-image side-l-i" src={SL} alt="side" /> */}</div>
        <div className='form-center'></div>
        <div className='side side-r'>{/* <img className="side-image" src={SR} alt="side" /> */}</div>
      </div>
      <div className='form-row form-b-r'>
        <div className='corner corner-bl'>
          <img className='corner-image' src={BL} alt='corner' />
        </div>
        <div className='side side-b'>{/* <img className="side-image side-b-i" src={SB} alt="side" /> */}</div>
        <div className='corner corner-br'>
          <img className='corner-image' src={BR} alt='corner' />
        </div>
      </div>
    </div>
  );
}

export default BGForm;
