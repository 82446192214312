import React from 'react'
import styles from './TacDisplay.module.scss';
import classNames from 'classnames';
import QR from '../../assets/Privacy_QR.png';

function Privacy() {
    return (
        <div className={classNames(styles.tacObject, styles.privacy)}>

            <h1>We value your privacy</h1>
            Our privacy policies and other disclosures contain information as to how we collect, use, protect and disclose your personal information. The privacy notice(s) that apply to you vary based on the type of relationship you have with State Farm® and the state in which you live. Please see below for more.


            <h1>Notice of Privacy Policy</h1>
            You are subject to the Notice of Privacy Policy which is required by the federal Gramm-Leach-Bliley Act (GLBA) under either of the following conditions:
            <ul>
                <li>You have financial products or services (banking, securities, or insurance) which are used primarily for personal, family, or household purposes</li>
                <li>State Farm has your information in connection with these types of products</li>
            </ul>
            {/* LINKS */}
            Read the full privacy policy
            Notice of Privacy Policy FAQs
            Solicitations and direct marketing FAQs

            <h2>About our ads and tracking</h2>
            We, along with our vendors, use several common tracking technologies on our website properties and in our mobile applications to collect information about your computer or device. These may include:

            <ul>
                <li>Browser cookies</li>
                <li>Local shared objects (such as "Flash" cookies)</li>
                <li>Mobile advertising or device IDs</li>
                <li>Analytical tools</li>
                <li>Other technologies</li>
            </ul>
            {/* LINK */}
            Read the full policy

            <h2>Health Insurance Portability and Accountability Act (HIPAA) privacy policy</h2>
            You’re subject to HIPAA, if you are:

            A consumer who has purchased a health insurance policy
            A State Farm employee or independent contractor agent and have enrolled in a State Farm group health plan
            Read the full HIPAA Privacy Policy

            <h1>State-required notices</h1>
            State laws and regulations require certain privacy notices be provided depending on the State Farm product or service you have and the jurisdiction.

            You are subject to the Insurance Information and Privacy Protection Act notice, if you:
            <ul>
                <li>
                    Have insurance products (auto, homeowners, life, or health)
                </li>
                <li>
                    Are a claimant or beneficiary related to insurance products primarily for personal, family or household purposes
                </li>
            </ul>

            This notice may apply in addition to the notices described above, which are provided in accordance with GLBA and/or HIPAA. This notice will be substantially similar to what is provided in the following link, but your state’s exact notice and rights could vary.

            {/* LINK */}
            Read the State Privacy Rights notice

            <h2>Notice of Privacy Policy to Consumers</h2>
            If you are a resident of Maine or Montana, we are required to provide this notice as a result of your involvement in a claim with one of our customers and this is provided whether or not you are an existing State Farm customer. You might receive this notice if you were involved:

            <ul>
                <li>
                    As the driver of another vehicle in an automobile accident with a State Farm-insured customer
                </li>
                <li>
                    As a passenger in a vehicle involved in a claim with a State Farm-insured customer
                </li>
                <li>
                    In a claim recorded under a State Farm Homeowners policy
                </li>
            </ul>

            {/* LINK */}
            Read the full Privacy Policy to Consumers

            <h2>California Consumer Privacy Act (CCPA)</h2>
            If you are a resident of California, you may have protections, subject to certain limitations, under the CCPA notice. In general, consumers subject to this notice will be those who do not have an existing customer relationship with State Farm.

            {/* LINKS */}
            General California consumers and customers of State Farm should click here for applicable CCPA notice
            Current and former State Farm: job applicants, employees, State Farm Agents, agent team members, contractor and person we interact with in their capacity of representing another business (eg. business to business contacts) click here for the applicable CCPA Notice

            If you are a California resident and would like to exercise your CCPA rights, utilize the applicable link below to access and complete the data access request form.

            {/* LINKS */}
            California Consumers without State Farm online access - Request Form
            California Consumers with State Farm online access - Request Form

            <h2>Domestic abuse</h2>
            Some states require notices to victims of domestic abuse of their rights granted by each state.

            {/* LINKS */}
            California Confidentiality Request
            Read Illinois Domestic Abuse Notice
            Read New York Domestic Abuse Notice
            Read Washington Protected Individual Notice

            <br />
            <h2>View on State Farm Website</h2>
            <img src={QR} alt="QR Code" />
            www.statefarm.com/customer-care/privacy-security/privacy

            <br />
            <br />
            *Some links have been removed for this experience, if you wish to view the full experience please visit the State Farm website at https://www.statefarm.com/
        </div>
    )
}

export default Privacy