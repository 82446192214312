import React from 'react';
import styles from './TacDisplay.module.scss';
import classNames from 'classnames';
import QR from '../../assets/DNS_QR.png';

function DNS() {
  return (
    <div className={classNames(styles.tacObject, styles.terms)}>
      <h1>State Farm® California Privacy Rights</h1>
      <h2>California residents only</h2>
      <h2>Notice of Right to Opt Out of Sale or Sharing of Personal Information</h2>
      The California Consumer Privacy Act (CCPA) gives California residents the right to opt out of the “sale” or
      “sharing” of personal information. We do not sell your personal information (PI) for monetary consideration.
      Third-Party digital businesses (“Third-Party Digital Businesses”) may associate cookies and other tracking
      technologies that collect personal information about you on our website, or otherwise collect and process personal
      information that we make available about you, including digital activity information. Giving access to personal
      information on our website, or otherwise, to Third-Party Digital Businesses could be deemed a “sale” and/or
      “sharing”. Therefore, we will treat such PI collected by Third-Party Digital Businesses (e.g., cookie ID, IP
      address, and other online IDs and internet or other electronic activity information) as such, and subject to the
      opt-out requests described above. In some instances, the PI we make available about you is collected directly by
      such Third-Party Digital Businesses using cookies or other tracking technologies on our website or our
      advertisements that are served on third-party sites (which we refer to as “cookie PI”).
      <br />
      Please refer to the section “Sale or Sharing of Personal Information" in the CCPA Notice for more details.
      <br />
      Opt-out for cookie PI: You can exercise this right with respect to cookie PI by using the Do Not Sell/Share
      functionality below. You will need to click on each “toggle” within the cookie preferences menu and move it to the
      left, then click “save” in order to stop the collection of your personal information by the cookies and trackers.
      Your choice will only apply to the browser and device you are currently using to visit our websites. It will not
      apply to a different browser or device. If you visit our webpages using a different browser or device, you will
      need to exercise your choice for each browser or device. If you have a cookie blocker or similar tool enabled, it
      may prevent our cookie banner and the cookies preferences menu from appearing. Your preferences are saved via an
      essential cookie, so if you clear cookies from your browser, it will also clear the opt-out preferences that you
      have made.
      <br />
      If you do not wish to exercise your opt-out right with respect to all cookies, you can manage individual cookie
      preferences. However, you must follow the above instructions as to all cookie categories presented to you in order
      to opt out of the “sale” or “sharing” of your personal information. Please be aware that when you toggle off all
      cookies, the way our website functions may change. If at any point you want to update your cookie preferences, you
      may do so by clicking on the Do Not Sell or Share My Personal Information link which you can find at the bottom of
      each webpage.
      <br />
      The CCPA requires us to state how we handle and process Global Privacy Control (“GPC”) signals, which is referred
      to in the CCPA as opt-out preference signal (“OOPS”). GPC/OOPS are signals sent by a platform, technology, or
      mechanism, enabled by individuals on their devices or browsers, that communicate the individual’s choice to
      opt-out of the Sale and Sharing of personal information. To use an OOPS/GPC, you can download an internet browser
      or a plugin to use on your current internet browser and follow the settings to enable the OOPS/GPC. We have
      configured the settings of our consent management platform to receive and process GPC signals on our websites.
      Similar to when you configure cookies manually, GPC/OOPS will only apply to the browser and device you are
      currently using to visit our websites. It will not apply to a different browser or device. If you visit our
      webpages using a different browser or device, you will need to exercise your choice for each browser or device.
      <br />
      <h2>View on State Farm Website</h2>
      <img src={QR} alt='QR Code' />
      www.statefarm.com/customer-care/privacy-security/privacy/california-privacy-rights/do-not-sell
      <br />
      <br />
      *Some links have been removed for this experience, if you wish to view the full experience please visit the State
      Farm website at https://www.statefarm.com/
    </div>
  );
}

export default DNS;
