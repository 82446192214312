import React from 'react'
import styles from './LeaderboardCell.module.scss'
import classNames from 'classnames';

function LeaderboardCell(props) {
    const { rank, teamName, score, isSpecial, outlined } = props;
    return (
        <div className={classNames(styles.leaderItem, { [styles.blackBack]: isSpecial, [styles.outlined]: outlined })}>
            <div className={styles.leaderRank}>{rank}</div>
            <div className={styles.leaderName}>{teamName}</div>
            <div className={styles.leaderScore}>{String(Math.floor(score)).padStart(4, '0').replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</div>
        </div>
    )
}

export default LeaderboardCell