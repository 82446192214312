import Axios from 'axios';
// import { Auth } from "aws-amplify";

const BASE_URL = process.env.REACT_APP_BASE_URL;
console.log('BASE URL: ' + BASE_URL);
Axios.defaults.baseURL = BASE_URL;

const allowedHeaders = [
  'authorization',
  'access-control-allow-origin',
  'content-type',
  'data',
  'body',
  'get',
  'put',
  'post',
  'delete',
  'head',
  'option'
];

const instance = Axios.create({
  baseURL: BASE_URL,
  timeout: 8000,
  transformRequest: [
    function (data, header) {
      // Strip the headers of all but the allowedHeaders
      var keys = Object.keys(header);
      for (var a = 0; a < keys.length; a++) {
        if (!allowedHeaders.includes(keys[a].toLowerCase())) {
          delete header[keys[a]];
        }
      }
      return data;
    }
  ]
});

class SendRequest {
  // static testHeaderSize = () => {
  //     return new Promise((resolve, reject) => {
  //         Auth.currentSession().then((session) => {
  //             instance.post('/api/user/badges/3143/log', {}, {
  //                 headers: {
  //                     'Authorization': 'Bearer ' + session.getAccessToken().getJwtToken(),
  //                     'Access-Control-Allow-Origin': '*',
  //                 }
  //             }).then(result => {
  //                 console.log(result);
  //                 resolve(result);
  //             }).catch(err => {
  //                 console.log(err);
  //                 reject(err.response && err.response.data ? err.response.data : err);
  //             });
  //         }).catch(err => {
  //             console.log(err);
  //             reject(err.message ? err.message : err);
  //         });
  //     });
  // }

  static sendGet = (url, token) => {
    return new Promise((resolve, reject) => {
      // Auth.currentSession()
      //   .then((session) => {
      instance
        .get(url, {
          headers: {
            Authorization: 'Bearer ' + token,
            'Access-Control-Allow-Origin': '*'
          }
        })
        .then((result) => {
          resolve(result);
        })
        .catch((err) => {
          if (err.response && err.response.status === 404) reject('Unable to find data.');
          else if (err.response && err.response.status === 401) reject('Not authenticated. Please login again.');
          else if (err.response && err.response.status === 400) reject('Bad request, please try again.');
          else reject(err.response && err.response.data ? err.response.data : err);
        });
      // })
      // .catch((err) => {
      //   console.log(err);
      //   reject(err.message ? err.message : err);
      // });
    });
  };
  // // static sendRawGet = (url) => {
  // //     return new Promise((resolve, reject) => {
  // //         Auth.currentSession().then((session) => {
  // //             var ins = Axios.create({});
  // //             ins.get(url, {
  // //                 headers: {
  // //                     'Authorization': 'Bearer ' + session.getAccessToken().getJwtToken(),
  // //                     'Access-Control-Allow-Origin': '*'
  // //                 }
  // //             }).then(result => {
  // //                 resolve(result);
  // //             }).catch(err => {
  // //                 if (err.response && err.response.status === 404)
  // //                     reject('Unable to find data.')
  // //                 else if (err.response && err.response.status === 401)
  // //                     reject('Not authenticated. Please login again.')
  // //                 else if (err.response && err.response.status === 400)
  // //                     reject('Bad request, please try again.');
  // //                 else
  // //                     reject(err.response && err.response.data ? err.response.data : err);
  // //             });
  // //         }).catch(err => {
  // //             console.log(err);
  // //             reject(err.message ? err.message : err);
  // //         });
  // //     });
  // // }

  static sendPost = (url, body, token) => {
    return new Promise((resolve, reject) => {
      // Auth.currentSession()
      //   .then((session) => {
      var ins = Axios.create({
        baseURL: BASE_URL,
        timeout: 15000
      });
      ins
        .post(url, body, {
          headers: {
            Authorization: 'Bearer ' + token,
            'Access-Control-Allow-Origin': '*'
          }
        })
        .then((result) => {
          resolve(result);
        })
        .catch((err) => {
          if (err.response && err.response.status === 404) reject('Unable to find data.');
          else if (err.response && err.response.status === 401) reject('Not authenticated. Please login again.');
          // else if (err.response.status === 400)
          //     reject('Bad request, please try again.');
          else {
            console.log(err);
            reject(err.response && err.response.data ? err.response.data : err);
          }
        });
      // })
      // .catch((err) => {
      //   reject(err.message ? err.message : err);
      // });
    });
  };
  // static sendPut = (url, body) => {
  //   return new Promise((resolve, reject) => {
  //     var ins = Axios.create({
  //       baseURL: BASE_URL,
  //       timeout: 15000,
  //     });
  //     Auth.currentSession()
  //       .then((session) => {
  //         ins
  //           .put(url, body, {
  //             headers: {
  //               Authorization: "Bearer " + session.getAccessToken().getJwtToken(),
  //               "Access-Control-Allow-Origin": "*",
  //             },
  //           })
  //           .then((result) => {
  //             resolve(result);
  //           })
  //           .catch((err) => {
  //             if (err.response && err.response.status === 404) reject("Unable to find data.");
  //             else if (err.response && err.response.status === 401) reject("Not authenticated. Please login again.");
  //             // else if (err.response.status === 400)
  //             //     reject('Bad request, please try again.');
  //             else reject(err.response && err.response.data ? err.response.data : err);
  //           });
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //         reject(err.message ? err.message : err);
  //       });
  //   });
  // };

  // static sendDelete = (url) => {
  //   return new Promise((resolve, reject) => {
  //     var ins = Axios.create({
  //       baseURL: BASE_URL,
  //       timeout: 15000,
  //     });
  //     Auth.currentSession()
  //       .then((session) => {
  //         ins
  //           .delete(url, {
  //             headers: {
  //               Authorization: "Bearer " + session.getAccessToken().getJwtToken(),
  //               "Access-Control-Allow-Origin": "*",
  //             },
  //           })
  //           .then((result) => {
  //             resolve(result);
  //           })
  //           .catch((err) => {
  //             if (err.response && err.response.status === 404) reject("Unable to find data.");
  //             else if (err.response && err.response.status === 401) reject("Not authenticated. Please login again.");
  //             // else if (err.response.status === 400)
  //             //     reject('Bad request, please try again.');
  //             else {
  //               console.log(err);
  //               reject(err.response && err.response.data ? err.response.data : err);
  //             }
  //           });
  //       })
  //       .catch((err) => {
  //         reject(err.message ? err.message : err);
  //       });
  //   });
  // };

  /**
   * Test transforming the post for registration.
   * Attempting to trim out excessive cookies that are causing the load balancer to reject the
   * request due to excessive header size. 64K max header size is hard limit for AWS Loadbalancer
   * @param {*} url
   * @param {*} body
   * @returns
   */
  static sendNoAuthPost = (url, body) => {
    return new Promise((resolve, reject) => {
      var ins = Axios.create({
        baseURL: BASE_URL,
        timeout: 15000
      });
      console.log('NO AUTH BASE URL: ', BASE_URL);
      ins
        .post(url, body, {
          headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json'
          }
        })
        .then((result) => {
          resolve(result);
        })
        .catch((err) => {
          if (err.response && err.response.status === 431)
            reject('Error in request. Please use a different browser or try again after clearing cookies.');
          else reject(err.response && err.response.data ? err.response.data : err);
        });
    });
  };
  static sendNoAuthGet = (url) => {
    return new Promise((resolve, reject) => {
      var ins = Axios.create({
        baseURL: BASE_URL,
        timeout: 15000
      });
      ins
        .get(url, {
          headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json'
          }
        })
        .then((result) => {
          resolve(result);
        })
        .catch((err) => {
          if (err.response && err.response.status === 431)
            reject('Error in request. Please use a different browser or try again after clearing cookies.');
          else reject(err.response && err.response.data ? err.response.data : err);
        });
    });
  };
}

export default SendRequest;
