import React, { useEffect, useState } from 'react';
import styles from './Forms.module.scss';
import classnames from 'classnames';
import header from '../../../assets/registration/register-header.png';

const regex = new RegExp(
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
);
const USE_DEV = false;

function NewUser(props) {
  const { submit, loading, setOpenLink } = props;

  const [valid, setValid] = useState(false);
  const [inputs, setInputs] = useState({
    firstName: '',
    lastName: '',
    email: '',
    rulesAgree: false,
    tandc: false,
    ageConf: false
  });

  useEffect(() => {
    if (USE_DEV && inputs.firstName === 'dev') {
      // setValid(true);
      // function makeid(length) {
      //   let result = '';
      //   const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
      //   const charactersLength = characters.length;
      //   let counter = 0;
      //   while (counter < length) {
      //     result += characters.charAt(Math.floor(Math.random() * charactersLength));
      //     counter += 1;
      //   }
      //   return result;
      // }
      // const em = `${makeid(10)}@email.net`;
      // const fn = `${makeid(12)}`;
      // const ln = `${makeid(4)}`;
      // setInputs({ firstName: fn, lastName: ln, email: em, tandc: true, ageConf: true, rulesAgree: true });
      // submit({ firstName: fn, lastName: ln, email: em, tandc: true, ageConf: true, rulesAgree: true });
      // return;
    } else {
      const v =
        regex.test(inputs.email.toLowerCase()) &&
        inputs.firstName.length > 0 &&
        inputs.lastName.length > 0 &&
        inputs.ageConf;
      // && inputs.rulesAgree &&
      // inputs.tandc;
      setValid(v);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputs]);

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && valid && !loading) {
      submit(inputs);
    }
  };
  const setLink = (e, link) => {
    e.preventDefault();
    e.stopPropagation();
    setOpenLink(link);
  };
  return (
    <div className={classnames(styles.newuser, styles.registerForm)}>
      <div id='formContainer' className={classnames(styles.formContainer)}>
        <img src={header} className={classnames(styles.headerTitle)} alt={'Register'} />
        <div className={styles.formInputs}>
          <input
            id='firstName'
            className={classnames(styles.formInput, styles.inputA)}
            value={inputs['firstName']}
            // onFocus={() => setInputName("firstName")}
            placeholder={'First Name'}
            onChange={(e) => setInputs({ ...inputs, firstName: e.target.value })}
          />
          <input
            id='lastName'
            className={classnames(styles.formInput, styles.inputB)}
            value={inputs['lastName']}
            // onFocus={() => setInputName("lastName")}
            placeholder={'Last Name'}
            onChange={(e) => setInputs({ ...inputs, lastName: e.target.value })}
          />
          <input
            id='email'
            className={classnames(styles.formInput, styles.inputC)}
            value={inputs['email']}
            // onFocus={() => setInputName("email")}
            placeholder={'Email'}
            onChange={(e) => setInputs({ ...inputs, email: e.target.value })}
            onKeyDown={handleKeyDown}
          />
        </div>
        <div className={styles.formChecks}>
          {/* <div className={styles.boxc}> */}
          <label className={styles.tacHeader}>Please agree and check the following</label>
          <label className={styles.checks}>
            <input
              id='ageConf'
              type='checkbox'
              checked={inputs.ageConf}
              // onFocus={() => setInputName("ageConf")}
              onChange={(e) => {
                setInputs((prev) => ({ ...prev, ageConf: e.target.checked }));
              }}
            />
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'flex-start'
              }}
            >
              I am 18 years or older
              <div style={{ fontSize: '12px', color: '#666666', lineHeight: '14px', width: '380px' }}>
                For NE and AL residents you must be 19 years or older and for MS residents you must be 21 years or
                older.
              </div>
              {/* I confirm that I am 18 years or older and and I consent to State Farm{' '}
                <span onClick={(e) => setLink(e, 'terms')}>Terms and Conditions</span>,{' '}
                <span onClick={(e) => setLink(e, 'privacy')}>Privacy Policy</span>, and{' '}
                <span onClick={(e) => setLink(e, 'rules')}>Rules</span>. */}
            </div>
          </label>
          {/* <label className={styles.checks}>
            <input
              id='rulesAgree'
              type='checkbox'
              checked={inputs.rulesAgree}
              onChange={(e) => setInputs((prev) => ({ ...prev, rulesAgree: e.target.checked }))}
            />
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              I have reviewed and agree to the &nbsp;
              <span onClick={(e) => setLink(e, 'rules')}> Jingle Quest Rules</span>.
            </div>
          </label> */}
          <label className={styles.checks}>
            <input
              id='tandc'
              type='checkbox'
              checked={inputs.tandc}
              onChange={(e) => setInputs((prev) => ({ ...prev, tandc: e.target.checked }))}
            />
            <div>
              I understand and consent to Sponsor using my personal information that I provide in my Contest Entry for
              business, marketing, and commercial purposes consistent with Sponsors{' '}
              <span onClick={(e) => setLink(e, 'privacy')}>Privacy Policy</span>.
              <div style={{ fontSize: '12px', color: '#666666', lineHeight: '14px', width: '740px' }}>
                For CA residents:
                <span onClick={(e) => setLink(e, 'dns')}> Do Not Sell or Share My Personal Information</span> (CA
                residents only) Use for marketing may include direct and indirect communications from Sponsor and/or a
                third party acting on Sponsor’s behalf.
              </div>
            </div>
          </label>
        </div>

        <div className={styles.formDisclaimers}>
          <label className={styles.disclaimer}>
            All information submitted by entrants is subject to, and will be treated in a manner consistent with,
            Sponsor’s Terms of Use and <span onClick={(e) => setLink(e, 'privacy')}>Privacy Policy</span>. CA residents
            please see: <span onClick={(e) => setLink(e, 'dns')}>Do Not Sell or Share My Personal Information</span> (CA
            residents only). By participating in Contest, entrants hereby agree that Sponsor may collect and use their
            personal information in compliance with its Privacy Policy and acknowledge that they have read and accepted
            the Sponsor Privacy Policy. By participating in Contest, entrants further agree and acknowledge that Sponsor
            may share their personal information with third parties in order for the third party to market Sponsor’s
            products and services on behalf of Sponsor, consistent with Sponsor’s Privacy Policy.
          </label>
          <label className={styles.disclaimer}>
            Byclicking SUBMIT, you are providing express consent by electronic signature that you may have your event
            photograph sent via email by State Farm Mutual Automobile Insurance Company, its subsidiaries and affiliates
            (“State Farm”) using the email address you have provided to State Farm. By continuing, you agree to the
            terms of service.
          </label>
          <label className={styles.disclaimer}>
            You are granting to Infinity Marketing Team, LLC (EVENT SPONSOR), their agents, licensees and assigns an
            irrevocable, non-exclusive and unrestricted license to display and use the photograph and your likeness as
            they see fit, now and in the future, and to publish, adapt, edit and/or modify the photograph in any way, in
            whole or in part, and to use such photograph in any and all media now known or hereafter discovered,
            worldwide, including but not limited to Social Media Outlets, without limitation or compensation to you and
            without right of notice, review, or approval of any such use.
          </label>
          {/* </div> */}
        </div>

        <div className={styles.formButton}>
          <button
            className={classnames(styles.submitBtn, {
              [styles.disabled]: !valid || loading
            })}
            alt='Go'
            onClick={(e) => valid && !loading && submit(inputs)}
          >
            {loading ? <div className='progress-indicator' /> : `Submit`}
          </button>
        </div>
      </div>
    </div>
  );
}

export default NewUser;
