import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Fade } from '@mui/material';

import styles from "./PhotoRegThanks.module.scss";
// import classnames from "classnames";

import headerLogo from '../../assets/home/stateLogoRed.png';
import carImage from '../../assets/trivia/ready/car.png';

import Menu from '../../Components/Menu/Menu';

export const PhotoRegThanks = (props) => {
    const navigate = useNavigate();
    const [fade, setFade] = useState(true);
    const [menuOpen, setMenuOpen] = useState(false);

    useEffect(() => {
        const a = setTimeout(() => {
            setFade(false);
            setMenuOpen(false);
            setTimeout(() => {
                navigate("/photo");
            }, 700);
        }, 10 * 1000);
        return () => clearTimeout(a);
    }, [])

    return (<Fade in={fade} timeout={700} >
        <div id='ready' className={styles.photoThanks}>
            <Menu menuOpen={menuOpen} setMenuOpen={setMenuOpen} hideModes={true} resetPath="/photo" />
            <img className={styles.stateLogo} src={headerLogo} onClick={() => setMenuOpen(true)} alt="StateFarmlogo" />
            {/* <div className={styles.container}> */}
            <div className={styles.readyFlex}>
                <div className={styles.readyTitle}>
                    <div className={styles.readyTit1}>THANK YOU.</div>
                </div>
                <div className={styles.readyDetails}>
                    <p className={styles.readyTit2}>Please visit the photo experience to take your photo!</p>

                </div>
            </div>
            <div className={styles.carContainer}>
                <img className={styles.car} src={carImage} alt="beigeBackground" />
            </div>
        </div>
    </Fade>
    );
}


export default PhotoRegThanks;