import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import {
  Avatar,
  Drawer,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemIcon,
  ListItemText
} from '@mui/material';

// import PersonIcon from '@mui/icons-material/Person';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import CloseIcon from '@mui/icons-material/Close';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import RefreshIcon from '@mui/icons-material/Refresh';
import TvIcon from '@mui/icons-material/Tv';
import QrCodeIcon from '@mui/icons-material/QrCode';
import QuizIcon from '@mui/icons-material/Quiz';
// import LoginIcon from '@mui/icons-material/Login';
// import HomeIcon from '@mui/icons-material/Home';
// import CircleIcon from '@mui/icons-material/Circle';

import styles from './Menu.module.scss';
import logo from '../../assets/sf_red_logo.png';
import classNames from 'classnames';
import { selectAppState, setAppState, clear as clearUser } from '../../store/reducers/userSlice';
import { clear as clearTrivia } from '../../store/reducers/triviaSlice';
// import { HEADERS, HEADER_LINKS } from '../../../util/constants';
// import { getCurrentEpisode } from '../../../reducers/episodeSlice';
import QR from '../../assets/State_Farm_QR.png';

// SHOW OR HIDE LOGIN AND USER BASED COMPONENTS
// const SHOW_LOGIN = process.env.REACT_APP_SHOW_LOGIN === "true";
// const PUBLIC_URL = process.env.PUBLIC_URL;
// const headerLinks = ["/#starring", "/#howtoplay", "/#episodes", "/#rules", "/#previousseason"];

const Menu = (props) => {
  const { menuOpen, setMenuOpen, hideModes, resetPath } = props;
  const navigate = useNavigate();
  const appState = useSelector(selectAppState);
  const dispatch = useDispatch();

  const getItemIcon = (name, index = 0) => {
    if (appState === name) return <RadioButtonCheckedIcon fontSize='small' color='error' />;
    else return <RadioButtonUncheckedIcon fontSize='small' />;
  };

  const handleModeSelect = (mode) => {
    dispatch(setAppState(mode));
    setMenuOpen(false);
  };

  const reset = (e) => {
    setMenuOpen(false);
    dispatch(clearUser());
    dispatch(clearTrivia());
    setTimeout(() => {
      resetPath ? navigate(resetPath) : navigate('/playtrivia');
    }, 700);
  };
  const goTo = (path) => {
    setMenuOpen(false);
    dispatch(clearUser());
    dispatch(clearTrivia());
    setTimeout(() => {
      navigate(path);
    }, 700);
  };
  // const goToDisplay = (e) => {
  //     setMenuOpen(false);
  //     dispatch(clearTrivia());
  //     setTimeout(() => { navigate('/displayboard') }, 700)
  // }

  return (
    <Drawer className={styles.menuDrawer} anchor={'left'} open={menuOpen} onClose={() => setMenuOpen(false)}>
      <div className={styles.menuHeader}>
        <img src={logo} alt='' className={styles.menuLogo} />
        <div className={styles.menuCloseButton} onClick={() => setMenuOpen(false)}>
          <CloseIcon />
        </div>
      </div>
      <List>
        {!hideModes && (
          <ListItem
            className={styles.menuItemInsets}
            key={'mode_1_select'}
            onClick={() => handleModeSelect('mode1')}
            disablePadding
          >
            <ListItemButton>
              <ListItemIcon>{getItemIcon('mode1')}</ListItemIcon>
              <ListItemText
                className={classNames({
                  [styles.matched]: appState === 'mode1'
                })}
                primary={'Set Mode 1'}
              />
              {/* <ListItemText className={classNames({path == ('/rules') : 'matched' })} primary={'Rules'} /> */}
            </ListItemButton>
          </ListItem>
        )}
        {!hideModes && (
          <ListItem
            className={styles.menuItemInsets}
            key={'mode_2_select'}
            onClick={() => handleModeSelect('mode2')}
            disablePadding
          >
            <ListItemButton>
              <ListItemIcon>{getItemIcon('mode2')}</ListItemIcon>
              <ListItemText
                className={classNames({
                  [styles.matched]: appState === 'mode2'
                })}
                primary={'Set Mode 2'}
              />
            </ListItemButton>
          </ListItem>
        )}
        {/* <ListItem className={styles.menuItemInsets} key={'homelink'} onClick={goHome} disablePadding >
                <ListItemButton>
                    <ListItemIcon>
                        {getItemIcon('nadada')}
                    </ListItemIcon>
                    <ListItemText primary={'Home'} />
                </ListItemButton>
            </ListItem> */}
        {/* <ListItem className={styles.menuItemInsets} key={'trivia_link'} onClick={e => goTo('/playtrivia')} disablePadding >
                <ListItemButton>
                    <ListItemIcon>
                        <QuizIcon fontSize="small" />

                    </ListItemIcon>
                    <ListItemText primary={'Go to Trivia'} />
                </ListItemButton>
            </ListItem> */}
        <ListItem className={styles.menuItemInsets} key={'jingle_link'} onClick={() => goTo('/jjq')} disablePadding>
          <ListItemButton>
            <ListItemIcon>
              <QuizIcon fontSize='small' />
            </ListItemIcon>
            <ListItemText primary={'Go to Jingle Quest'} />
          </ListItemButton>
        </ListItem>
        <ListItem
          className={styles.menuItemInsets}
          key={'registration_link'}
          onClick={(e) => goTo('/photo')}
          disablePadding
        >
          <ListItemButton>
            <ListItemIcon>
              <CameraAltIcon fontSize='small' />
            </ListItemIcon>
            <ListItemText primary={'Go to Photo Registration'} />
          </ListItemButton>
        </ListItem>
        <ListItem className={styles.menuItemInsets} key={'baqr_link'} onClick={(e) => goTo('/baqr')} disablePadding>
          <ListItemButton>
            <ListItemIcon>
              <QrCodeIcon fontSize='small' />
            </ListItemIcon>
            <ListItemText primary={'Go to BA QR'} />
          </ListItemButton>
        </ListItem>

        <ListItem
          className={styles.menuItemInsets}
          key={'leaderboard_link'}
          onClick={(e) => goTo('/displayboard')}
          disablePadding
        >
          <ListItemButton>
            <ListItemIcon>
              <TvIcon fontSize='small' />
            </ListItemIcon>
            <ListItemText primary={'Rotating Display'} />
          </ListItemButton>
        </ListItem>

        <ListItem className={styles.menuItemInsets} key={'reset_link'} onClick={reset} disablePadding>
          <ListItemButton>
            <ListItemIcon>
              <RefreshIcon fontSize='small' />
            </ListItemIcon>
            <ListItemText primary={'Reset'} />
          </ListItemButton>
        </ListItem>
      </List>
      <div className={styles.menuFooter}>
        <div className={styles.linkContainer}>
          <img src={QR} alt='StateFarm.com' />
          Visit www.statefarm.com
        </div>
        <div className={styles.menuFooterText}>© 2023 - All Rights Reserved</div>
      </div>
    </Drawer>
  );
};

export default Menu;
