import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { clear } from '../../store/reducers/userSlice';
import { API_DOMAIN_KEY, API_TOKEN_KEY } from '../../api/constants';

function Logout() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  React.useEffect(() => {
    localStorage.removeItem(API_TOKEN_KEY);
    // localStorage.removeItem(API_DOMAIN_KEY);
    // localStorage.removeItem(GAME_REDIRECT);
    dispatch(clear());
    navigate('/jjq');
  }, []);

  return <div>Logout</div>;
}

export default Logout;
