import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import styles from './Forms.module.scss';
import classnames from 'classnames';
import header from '../../../assets/registration/play-again-header.png';
import { REG_TYPES, setRegistrationType } from '../../../store/reducers/userSlice';

const regex = new RegExp(
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
);
const USE_DEV = true;

function ReturningUser(props) {
  const { submit, loading, showReg } = props;
  const dispatch = useDispatch();
  const [email, setEmail] = useState('');
  const [valid, setValid] = useState(false);

  useEffect(() => {
    if (USE_DEV && email === 'dev') {
      setValid(true);
      setEmail('email@place.net');
      submit({ email: 'email@place.net' });
      return;
    } else {
      const v = regex.test(email.toLowerCase());
      setValid(v);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email]);

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && valid && !loading) {
      submit({ email });
    }
  };

  return (
    <div className={classnames(styles.returning, styles.registerForm)}>
      <div id='formContainer' className={classnames(styles.formContainer)}>
        <img src={header} className={classnames(styles.headerTitle)} alt={'Register'} />
        <div className={styles.formInputs}>
          <input
            id='email'
            className={classnames(styles.formInput, styles.inputC)}
            value={email}
            placeholder={'Email'}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
            onKeyDown={handleKeyDown}
          />
        </div>
        <div className={styles.formButton}>
          <button
            className={classnames(styles.submitBtn, {
              [styles.disabled]: !valid || loading
            })}
            alt='Go'
            onClick={() => valid && !loading && submit({ email })}
          >
            {loading ? <div className='progress-indicator' /> : `Submit`}
          </button>
          {showReg && (
            <button
              className={classnames(styles.regBtn, {
                [styles.disabled]: loading
              })}
              alt='Go Register'
              onClick={() => dispatch(setRegistrationType(REG_TYPES.NEW))}
            >
              {loading ? <div className='progress-indicator' /> : `Register`}
            </button>
          )}
        </div>

        <div className={styles.formDisclaimers} style={{ marginTop: '150px' }}>
          <label className={styles.disclaimer}>
            By clicking SUBMIT, you are providing express consent by electronic signature that you may have your event
            photograph sent via email by State Farm Mutual Automobile Insurance Company, its subsidiaries and affiliates
            (“State Farm”) using the email address you have provided to State Farm. By continuing, you agree to the
            terms of service.
          </label>
          <label className={styles.disclaimer}>
            You are granting to Infinity Marketing Team, LLC (EVENT SPONSOR), their agents, licensees and assigns an
            irrevocable, non-exclusive and unrestricted license to display and use the photograph and your likeness as
            they see fit, now and in the future, and to publish, adapt, edit and/or modify the photograph in any way, in
            whole or in part, and to use such photograph in any and all media now known or hereafter discovered,
            worldwide, including but not limited to Social Media Outlets, without limitation or compensation to you and
            without right of notice, review, or approval of any such use.
          </label>
          {/* <label className={styles.disclaimer}>
            By proceeding, you are providing express consent by electronic signature that you may have your event
            photograph sent via text messages and/or email by State Farm Mutual Automobile Insurance Company, its
            subsidiaries and affiliates (“State Farm”) using the phone number and/or email address you have provided to
            State Farm. To proceed with your photo experience, click Next. By continuing, you agree to the terms of
            service.
          </label>
          <label className={styles.disclaimer}>
            The information you provide as identifying information (name, address, phone number) will be used for our
            business purposes including assessing your application, providing you a quote and/or products and services,
            processing your claims and maintaining security to confirm your identity. We use our business needs, along
            with legal and regulatory requirements to establish retention schedules for personal information. We retain
            personal information according to our retention schedules. To opt-out of the sale or sharing of your
            personal information, please go to www.statefarm.com® and click on the Do Not Sell or Share My Personal
            Information link. For more information, please go to www.statefarm.com/privacy.
          </label> */}
          {/* </div> */}
        </div>
      </div>
    </div>
  );
}

export default ReturningUser;
