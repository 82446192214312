import React from 'react';
import styles from './Base.module.scss';
import { List, ListItem, ListItemButton, ListItemText } from '@mui/material';

function Base() {
  return (
    <>
      <div>State Farm - Mecum </div>
      <div
        style={{
          width: '300px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <List>
          <ListItem>
            <ListItemButton
              onClick={(e) => {
                window.location.href = '/jjq';
              }}
            >
              <ListItemText className={styles.menuItem} primary={'Jingle Quest'} />
            </ListItemButton>
          </ListItem>
          <ListItem>
            <ListItemButton
              onClick={(e) => {
                window.location.href = '/photo';
              }}
            >
              <ListItemText className={styles.menuItem} primary={'Photo Registration'} />
            </ListItemButton>
          </ListItem>
          <ListItem>
            <ListItemButton
              onClick={(e) => {
                window.location.href = '/displayboard';
              }}
            >
              <ListItemText className={styles.menuItem} primary={'Display Board'} />
            </ListItemButton>
          </ListItem>
        </List>
      </div>
    </>
  );
}

export default Base;
